
import {
    fetchUtils,
} from 'react-admin'
import { stringify } from 'query-string'

const API_URL = process.env.REACT_APP_API_HOSTNAME

const mapGet = {
    'Website': 'superuser/websites',
    'Post': 'superuser/posts',
    'Legale': 'superuser/legales',
    'Media': 'superuser/medias',
    'Page': 'superuser/pages',
    'Menu': 'superuser/menus',
    'Text': 'superuser/texts',
    'Prompt': 'superuser/prompts',
    'Expense': 'superuser/expenses',
    'Income': 'superuser/incomes',
    'Banner': 'superuser/banners',
    'Seopath': 'superuser/seopaths',
    'Product': 'superuser/products',
    'Command': 'superuser/commands',
    'ShopPage': 'superuser/shoppages',
    'Tracking': 'superuser/trackings',
}

const mapPost = {
    'Website': 'superuser/website',
    'Post': 'superuser/post',
    'Legale': 'superuser/legale',
    'Media': 'superuser/media',
    'Page': 'superuser/page',
    'Menu': 'superuser/menu',
    'Prompt': 'superuser/prompt',
    'Expense': 'superuser/expense',
    'Income': 'superuser/income',
    'Banner': 'superuser/banner',
    'Seopath': 'superuser/seopath',
    'Tracking': 'superuser/tracking',
    'Product': 'superuser/product',
    'ShopPage': 'superuser/shoppage',
    'Command': 'superuser/command',
}

const { fetchJson } = fetchUtils

const formify = (params) => {
    const formData = new FormData();
    for (let i in params.data) {
        if (params.data[i] && params.data[i].rawFile) {
            formData.append(i, params.data[i].rawFile);
        } else {
            if (Array.isArray(params.data[i]))
                formData.append(i, JSON.stringify(params.data[i]));
            else
                formData.append(i, params.data[i]);
        }
    }
    return formData;
};

const formifyArray = (params) => {
    const formData = new FormData();
    for (let i in params.data) {
        if (i === "filedatas") {
            for (let j = 0; j < params.data[i].length; j++) {
                formData.append(i + j, params.data[i][j].rawFile);
            }
        } else {
            if (Array.isArray(params.data[i]))
                formData.append(i, JSON.stringify(params.data[i]));
            else
                formData.append(i, params.data[i]);
        }
    }
    return formData;
};

const dataProvider = {
    getList: (resource, params) => new Promise(resolve => {
        console.log('getList', { resource, params })
        const { page, perPage } = params.pagination
        const { field, order } = params.sort
        const query = {
            limit: perPage,
            offset: (page - 1) * perPage,
            sort_field: field,
            sort_order: order,
            ...params.filter
        }
        const url = `${API_URL}/${mapGet[resource]}?${stringify(query)}`
        const requestHeaders = new Headers()
        requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
        fetchJson(url, { headers: requestHeaders }).then(json => {
            json = JSON.parse(json.body)
            resolve({
                data: json.data,
                total: json.total
            })
        })
    }),
    getSimpleList: (resource, filter) => new Promise(resolve => {
        console.log('getList', { resource, filter })
        const query = {
            ...filter
        }
        const url = `${API_URL}/${mapGet[resource]}?${stringify(query)}`
        const requestHeaders = new Headers()
        requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
        fetchJson(url, { headers: requestHeaders }).then(json => {
            json = JSON.parse(json.body)
            resolve({
                data: json.data,
                total: json.total
            })
        })
    }),
    getOne: (resource, params) => new Promise(resolve => {
        console.log('getOne', { resource, params })
        const url = `${API_URL}/${mapGet[resource]}?${stringify({ id: params.id })}`
        const requestHeaders = new Headers()
        requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
        fetchJson(url, { headers: requestHeaders }).then(json => {
            json = JSON.parse(json.body)
            resolve({
                data: json.data[0],
            })
        })
    }),
    getMany: (resource, params) => new Promise(async (resolve) => {
        console.log('getMany', { resource, params })
        const result = []
        for (let i = 0; i < params.ids.length; i++) {
            const ret = await dataProvider.getOne(resource, { id: params.ids[i] })
            if (ret.data)
                result.push(ret.data)
        }
        console.log(result)
        resolve({ data: result })
    }),
    getManyReference: (resource, params) => new Promise(resolve => {
        console.log('getManyReference', { resource, params })
        resolve()
    }),
    create: (resource, params) => new Promise(resolve => {
        console.log('create', { resource, params })
        const url = `${API_URL}/${mapPost[resource]}`
        const requestHeaders = new Headers()
        requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
        const options = { headers: requestHeaders }
        options.method = 'POST'
        if (resource === 'Media') {
            options.body = formifyArray(params)
        } else {
            options.body = JSON.stringify(params.data)
        }
        fetchJson(url, options).then(json => {
            json = JSON.parse(json.body)
            resolve({ data: { ...params.data, id: json.id } })
        })
    }),
    update: (resource, params) => new Promise(resolve => {
        console.log('update', { resource, params })
        delete params.data.updatedAt
        delete params.data.createdAt
        const url = `${API_URL}/${mapPost[resource]}`
        const requestHeaders = new Headers()
        requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
        const options = { headers: requestHeaders }
        options.method = 'PUT'
        options.body = JSON.stringify(params.data)
        fetchJson(url, options).then(json => {
            json = JSON.parse(json.body)
            resolve({ data: { ...params.data } })
        })
    }),
    updateMany: (resource, params) => new Promise(resolve => {
        console.log('updateMany', { resource, params })
        resolve()
    }),
    delete: (resource, params) => new Promise(resolve => {
        console.log('delete', { resource, params })
        const url = `${API_URL}/${mapPost[resource]}`
        const requestHeaders = new Headers()
        requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
        const options = { headers: requestHeaders }
        options.method = 'DELETE'
        options.body = JSON.stringify({ id: params.id })
        fetchJson(url, options).then(json => {
            json = JSON.parse(json.body)
            resolve({ data: { ...params.id } })
        })
    }),
    deleteMany: (resource, params) => new Promise(async (resolve) => {
        console.log('deleteMany', { resource, params })
        for (let i = 0; i < params.ids.length; i++) {
            await dataProvider.delete(resource, { id: params.ids[i] })
        }
        resolve({ data: [...params.ids] })
    }),
    deleteAll: (resource, params) => new Promise((resolve, reject) => {
        const url = `${API_URL}/${mapPost[resource]}/all`
        const requestHeaders = new Headers()
        requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
        const options = { headers: requestHeaders }
        options.method = 'DELETE'
        fetchJson(url, options).then(json => {
            resolve()
        })
    }),
    customs: {
        trackingsGraph: (start, end, website) => new Promise(resolve => {
            let query = { start, end, website }
            const url = `${API_URL}/superuser/dashboard/trackings?${stringify(query)}`
            const requestHeaders = new Headers()
            requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
            fetchJson(url, { headers: requestHeaders }).then(json => {
                json = JSON.parse(json.body)
                resolve(json)
            })
        }),
        gainGraph: (start, end, website) => new Promise(resolve => {
            let query = { start, end, website }
            const url = `${API_URL}/superuser/dashboard/gain?${stringify(query)}`
            const requestHeaders = new Headers()
            requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
            fetchJson(url, { headers: requestHeaders }).then(json => {
                json = JSON.parse(json.body)
                resolve(json)
            })
        }),
        unsplashSearch: (q, nocache) => new Promise(resolve => {
            let query = { q, nocache }
            const url = `${API_URL}/superuser/images/unsplash/search?${stringify(query)}`
            const requestHeaders = new Headers()
            requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
            fetchJson(url, { headers: requestHeaders }).then(json => {
                json = JSON.parse(json.body)
                resolve(json)
            })
        }),
        pixabaySearch: (q, nocache) => new Promise(resolve => {
            let query = { q, nocache }
            const url = `${API_URL}/superuser/images/pixabay/search?${stringify(query)}`
            const requestHeaders = new Headers()
            requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
            fetchJson(url, { headers: requestHeaders }).then(json => {
                json = JSON.parse(json.body)
                resolve(json)
            })
        }),
        imagesSearchUpload: (body) => new Promise(resolve => {
            const url = `${API_URL}/superuser/images/search/upload`
            const requestHeaders = new Headers()
            requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
            const options = { headers: requestHeaders }
            options.method = 'POST'
            options.body = JSON.stringify(body)
            fetchJson(url, options).then(json => {
                json = JSON.parse(json.body)
                resolve()
            })
        }),
        openaiCompletion: (body) => new Promise(resolve => {
            const url = `${API_URL}/superuser/openai/completion`
            const requestHeaders = new Headers()
            requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
            const options = { headers: requestHeaders }
            options.method = 'POST'
            options.body = JSON.stringify(body)
            fetchJson(url, options).then(json => {
                json = JSON.parse(json.body)
                resolve(json)
            })
        }),
        openaiChat: (body) => new Promise(resolve => {
            const url = `${API_URL}/superuser/openai/chat`
            const requestHeaders = new Headers()
            requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
            const options = { headers: requestHeaders }
            options.method = 'POST'
            options.body = JSON.stringify(body)
            fetchJson(url, options).then(json => {
                json = JSON.parse(json.body)
                resolve(json)
            })
        })
    }

}

export default dataProvider
