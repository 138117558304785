import * as React from 'react';
import { FC, memo, useMemo, ReactElement, useState } from 'react';
import PropTypes from 'prop-types';
import ImageEye from '@material-ui/icons/PictureAsPdf';

import { Button } from 'react-admin';

import dataProvider from '../dataProvider'

const API_URL = process.env.REACT_APP_API_HOSTNAME;

class ShowButton extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            nb: 0
        }
    }

    render() {

        const {
            resource,
            basePath = '',
            icon = defaultIcon,
            label = 'Address',
            record,
            scrollToTop = true,
            ...rest
        } = this.props

        return (
            <div style={{
                position: 'relative',
            }}>
                <Button
                    label={label}
                    disabled={this.state.disabled}
                    onClick={() => {
                        window.open(`${API_URL}/superuser/command/pdf/${localStorage.getItem('token')}/${record.id}.pdf`)
                    }}
                    {...(rest)}
                >
                    <ImageEye />
                </Button >
            </div>
        )
    }
}

const defaultIcon = <ImageEye />;

ShowButton.propTypes = {
    basePath: PropTypes.string,
    icon: PropTypes.element,
    label: PropTypes.string,
    record: PropTypes.any,
    scrollToTop: PropTypes.bool,
};

const PureShowButton = memo(
    ShowButton,
    (props, nextProps) =>
        (props.record && nextProps.record
            ? props.record.id === nextProps.record.id
            : props.record == nextProps.record) && // eslint-disable-line eqeqeq
        props.basePath === nextProps.basePath &&
        props.to === nextProps.to &&
        props.disabled === nextProps.disabled
);

export default PureShowButton;