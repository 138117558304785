import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';

const TinyTextField = forwardRef((props, ref) => {

  const editorRef = useRef(null)

  useImperativeHandle(ref, () => ({
    change(content) {
      editorRef.current.setContent(content)
    }
  }));

  return (
    <div>
      <label>{props.label}</label>
      <Editor
        initialValue={props.defaultValue}
        onInit={(evt, editor) => editorRef.current = editor}
        init={{
          height: 600,
          menubar: false,
          plugins: [
            'advlist autolink lists link image',
            'charmap print preview anchor help',
            'searchreplace visualblocks code',
            'insertdatetime media table paste wordcount',
          ],
          toolbar:
            'undo redo | formatselect | ' +
            'bold italic backcolor | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent | ' +
            'removeformat | help',
        }}
        onEditorChange={props.onChange}
      />
    </div>
  );
});

export default TinyTextField;
