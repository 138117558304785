
import { Component } from 'react'
import Button from '@material-ui/core/Button'
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';


import dataProvider from '../dataProvider'
import authProvider from '../authProvider'
import WebsitesSelector from '../components/WebsitesSelector';

class Gallery extends Component {

    constructor(props) {
        super(props)
        this.state = {
            search: "",
            selectedWebsites: []
        }
    }

    componentDidMount() {
        authProvider.checkAuth().then(() => {
        }).catch(() => {
            authProvider.logout().then(() => window.location = '/#/login')
        })
    }

    handleWebsiteSelect(website, limit) {
        this.setState({ website, limit }, () => {
            dataProvider.getList('Media', {
                pagination: { page: 1, perPage: 10000 },
                sort: { field: "createdAt", order: "desc" },
                filter: website > 0 ? { website } : {}
            }).then(response => {
                this.setState({ medias: response.data, total: response.total })
            })
        })
    }

    submit() {
        dataProvider.update('Media', {
            data: { id: this.state.changedMedia.id, website_values: this.state.selectedWebsites.map(e => ({ value: e })) }
        }).then(response => {
            this.setState({ dialogOpen: false }, () => this.handleWebsiteSelect(this.state.website, this.state.limit))
        })
    }

    render() {
        return (
            <div>
                <WebsitesSelector
                    defaultWebsite={0}
                    hasAll
                    hasOrphan
                    handleWebsiteSelect={(websites, selectedWebsite) => {
                        this.setState({ websites }, () => this.handleWebsiteSelect(selectedWebsite, 20))
                    }}
                />
                {
                    this.state.total && (
                        <div style={{ marginTop: "10px", fontSize: "18px" }}>{this.state.total} images</div>
                    )
                }
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {(this.state.medias || [])
                        .filter(e => this.state.website !== -1 || this.state.websites.filter(e2 => e.websites.includes(e2.id)).length === 0)
                        .filter(e => e.s3_key.includes(this.state.search)).slice(0, this.state.limit).map((e, index) => (
                            <div key={index}
                                style={{ flex: '0 0 auto', margin: '10px' }}
                                onClick={() => this.setState({ dialogOpen: true, changedMedia: e })}
                            >
                                <Tooltip title={`${e.s3_key} (${this.state.websites.filter(e2 => e.websites.includes(e2.id)).map(e => e.ndd).join(' - ')})`} arrow>
                                    <img src={e.url} style={{ minWidth: "75px", height: "150px", border: '2px solid grey', cursor: "pointer" }} />
                                </Tooltip>
                            </div>
                        ))}
                </div>
                <div></div>
                {
                    (this.state.medias || []).filter(e => e.s3_key.includes(this.state.search)).length > this.state.limit && (
                        <Button onClick={() => this.setState({ limit: this.state.limit + 20 })} color="primary">
                            More
                        </Button>
                    )
                }

                <Dialog open={this.state.dialogOpen} onClose={() => this.setState({ dialogOpen: false })} maxWidth="lg" fullWidth>
                    <DialogTitle>Choose websites</DialogTitle>
                    <DialogContent>
                        <WebsitesSelector
                            multiple
                            handleWebsiteSelect={(websites, selectedWebsites) => {
                                this.setState({ websites, selectedWebsites })
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.submit()} color="primary">
                            Submit
                        </Button>
                        <Button onClick={() => this.setState({ dialogOpen: false })} color="primary">
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

export default Gallery
