import * as React from 'react'
import { FC, AnchorHTMLAttributes, memo } from 'react'
import get from 'lodash/get'
import { ImageField, FileField, sanitizeFieldRestProps } from 'react-admin'
import { Typography, Link } from '@material-ui/core'

const OptionalImageField = memo(
    (props) => {
        const value = (get(props.record, props.source) || '').toLowerCase()

        if (value.endsWith('.png') || value.endsWith('.jpg') || value.endsWith('.jpeg') || value.endsWith('.gif') || value.endsWith('.webp')) {
            return (
                <div style={{ maxWidth: "200px", overflow:"hidden" }}><ImageField {...props} /></div>
            )
        }
        return (
            <FileField {...props} />
        )
    }
)

export default OptionalImageField