
import { Component } from 'react'
import {
    fetchUtils,
} from 'react-admin'
import { stringify } from 'query-string'
import { LineChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Line } from 'recharts'
import { PieChart, Pie, Sector, Cell } from 'recharts'
import { AreaChart, Area } from 'recharts'
import { BarChart, Bar } from 'recharts'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import Chart from "react-apexcharts";

import dataProvider from '../dataProvider'
import authProvider from '../authProvider'

class Graph extends Component {

    constructor(props) {
        super(props)
        this.state = {
            json: null,
            ago: !localStorage.getItem('ago') ? 1 : parseInt(localStorage.getItem('ago')),
            start: new Date(Date.now() - 24 * 3600 * 1000),
            end: new Date(),
            types: [],
            users: [],
            type: undefined,
            user: undefined,
        }
        this.agos = [
            [null, "None"],
            [5 * 60, "5 min ago"],
            [15 * 60, "15 min ago"],
            [30 * 60, "30 min ago"],
            [3600, "1 hour ago"],
            [3 * 3600, "3 hour ago"],
            [6 * 3600, "6 hour ago"],
            [12 * 3600, "12 hour ago"],
            [24 * 3600, "24 hour ago"],
            [48 * 3600, "48 hour ago"],
            [72 * 3600, "72 hour ago"],
            [7 * 24 * 3600, "7 days ago"],
            [30 * 24 * 3600, "30 days ago"],
        ]
    }

    componentDidMount() {
        authProvider.checkAuth().then(() => {
            dataProvider.getList('Website', { pagination: { page: 1, perPage: 1000 }, sort: {} }).then((res) => {
                this.setState({ websites: res.data, website: res.data[0] }, () => {
                    const ago = new URLSearchParams(window.location.search).get("ago")
                    if (ago) {
                        this.setState({ ago }, () => this.call())
                    } else {
                        this.call()
                    }
                })
            })
        }).catch(() => {
            authProvider.logout().then(() => window.location = '/#/login')
        })
    }

    call() {
        const query = {}
        if (this.state.ago) {
            query.start = new Date(Date.now() - this.agos[this.state.ago][0] * 1000).toISOString()
        } else {
            query.start = this.state.start.toISOString()
            query.end = this.state.end.toISOString()
        }
        dataProvider.customs.trackingsGraph(query.start, query.end, this.state.website.id).then((res) => {
            this.setState({ json: res })
        })
    }

    getWebsites() {
        dataProvider.getList('Websites', { pagination: { page: 1, perPage: 1000 }, sort: {} }).then((res) => {
            this.setState({ json: res })
        })
    }

    renderData() {
        if (!this.state.json)
            return <></>
        const formatXDate = (d) => {
            d = new Date(d)
            return `${d.getDate()},${d.getHours()}:${d.getMinutes()}`
        }
        const colors = [
            "#CC0000",
            "#CC7C00",
            "#CCCC00",
            "#00CC00",
            "#0000CC",
            "#2E2C5C",
            "#8C00CC",

            "#DD0000",
            "#DD7C00",
            "#DDDD00",
            "#00DD00",
            "#0000DD",
            "#2E2C5C",
            "#8C00DD",

            "#EE0000",
            "#EE7C00",
            "#EEEE00",
            "#00EE00",
            "#0000EE",
            "#2E2C5C",
            "#8C00EE",
        ]

        const commonOptions = {
            chart: {
                height: 330,
                type: 'donut',

            },
            colors: colors,
            plotOptions: {
                pie: {
                    startAngle: -90,
                    endAngle: 270,
                    donut: {
                        size: '80%',
                        labels: {
                            show: true,
                            total: {
                                show: true,
                                color: '#BCC1C8',
                                fontSize: '18px',
                                fontFamily: 'DM Sans',
                                fontWeight: 600,
                            },
                            value: {
                                show: true,
                                fontSize: '25px',
                                fontFamily: 'DM Sans',
                                fontWeight: 700,
                                color: '#8F9FBC',

                            },

                        }
                    }
                }
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                lineCap: 'round'
            },
            grid: {
                padding: {

                    bottom: 0,
                }
            },
            legend: {
                position: 'bottom',
                offsetY: 8,
                show: true,
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        height: 268
                    }
                }
            }]
        }

        const commonOptionsTimeline = {
            options: {

                colors,
                chart: {
                    fontFamily: 'DM Sans',
                    toolbar: {
                        show: false,
                    },
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'smooth'
                },
                fill: {
                    type: 'gradient',
                    gradient: {
                        shade: 'light',
                        type: "vertical",
                        shadeIntensity: 0.5,
                        inverseColors: false,
                        opacityFrom: .8,
                        opacityTo: .2,
                        stops: [0, 50, 100],
                        colorStops: []
                    }
                },
                grid: {
                    xaxis: {
                        lines: {
                            show: false
                        }
                    },
                    yaxis: {
                        lines: {
                            show: false
                        }
                    }
                },
                yaxis: {
                    labels: {
                        offsetY: 0,
                        minWidth: 20,
                        maxWidth: 20
                    },
                },
                xaxis: {
                    type: 'datetime',
                    labels: {
                        minHeight: 20,
                        maxHeight: 20
                    },
                },
                tooltip: {
                    x: {
                        format: 'dd/MM/yy HH:mm'
                    },
                },
            },
        }

        const trackingsGroupsBy_load = [
            ...Object.values(this.state.json.trackingsGroupsBy_load.reduce((acc, v) => {
                const key = v.value.split(' ')[0]
                if (acc[key]) {
                    acc[key].count += v.count
                } else {
                    acc[key] = {
                        ...v,
                        value: key
                    }
                }
                return acc
            }, {}))
        ]

        const trackingsGroupsBy_load_mobile = [
            ...Object.values(this.state.json.trackingsGroupsBy_load.reduce((acc, v) => {
                const dim = v.value.split(' ')[1].split('x').map(e => parseInt(e))
                if (dim[0] > dim[1])
                    acc['Desktop'].count += v.count
                else
                    acc['Mobile'].count += v.count
                return acc
            },
                { 'Desktop': { value: 'Desktop', count: 0 }, 'Mobile': { value: 'Mobile', count: 0 } }
            ))
        ]

        const trackingsGroupsBy_ads = [
            ...Object.values(this.state.json.trackingsGroupsBy_ads.reduce((acc, v) => {
                const key = v.value.split(' ')[1]
                if (acc[key]) {
                    acc[key].count += v.count
                } else {
                    acc[key] = {
                        ...v,
                        value: key
                    }
                }
                return acc
            }, {}))
        ]

        const chart0 = {
            options: {
                ...JSON.parse(JSON.stringify(commonOptions)),
                labels: this.state.json.trackingsGroupsBy_time.map(e => e.value),
            },
            series: this.state.json.trackingsGroupsBy_time.map(e => e.count),
        }
        chart0.options.plotOptions.pie.donut.labels.value.formatter = (val) => (2 * val) + 's'
        chart0.options.plotOptions.pie.donut.labels.total.formatter = (val) => (2 * val.globals.initialSeries.reduce((acc, v) => acc + v, 0)) + 's'

        const chart1 = {
            options: {
                ...commonOptions,
                labels: this.state.json.trackingGroups.filter(e => e.value !== 'time').map(e => e.value),
            },
            series: this.state.json.trackingGroups.filter(e => e.value !== 'time').map(e => e.count),
        }
        const chart2 = {
            options: {
                ...commonOptions,
                labels: trackingsGroupsBy_load.map(e => e.value),
            },
            series: trackingsGroupsBy_load.map(e => e.count),
        }
        const chart3 = {
            options: {
                ...commonOptions,
                labels: this.state.json.trackingsGroupsBy_click.map(e => e.value),
            },
            series: this.state.json.trackingsGroupsBy_click.map(e => e.count),
        }
        const chart4 = {
            options: {
                ...commonOptions,
                labels: trackingsGroupsBy_load_mobile.map(e => e.value),
            },
            series: trackingsGroupsBy_load_mobile.map(e => e.count),
        }
        const chart5 = {
            options: {
                ...commonOptions,
                labels: trackingsGroupsBy_ads.map(e => e.value),
            },
            series: trackingsGroupsBy_ads.map(e => e.count),
        }
        const chart6 = {
            options: {
                ...commonOptions,
                labels: this.state.json.trackingsGroupsBy_cart.map(e => e.value),
            },
            series: this.state.json.trackingsGroupsBy_cart.map(e => e.count),
        }

        const timeline_chart0 = {
            ...commonOptionsTimeline,
            series: this.state.json.trackingsGroupsBy_time.map(e => {
                return {
                    name: e.value,
                    data: this.state.json.trackingsGroupsBy_time_delta.map(list => list.filter(e2 => e2.value === e.value)[0]).map(e => e ? e.count : 0)
                }
            })
        }
        timeline_chart0.options.xaxis.categories = this.state.json.datesForTimeline

        const timeline_chart1 = {
            ...commonOptionsTimeline,
            series: this.state.json.trackingGroups.filter(e => e.value !== 'time').map(e => {
                return {
                    name: e.value,
                    data: this.state.json.trackingGroups_delta.map(list => list.filter(e2 => e2.value === e.value)[0]).map(e => e ? e.count : 0)
                }
            })
        }
        timeline_chart1.options.xaxis.categories = this.state.json.datesForTimeline

        const timeline_chart2 = {
            ...commonOptionsTimeline,
            series: trackingsGroupsBy_load.map(e => {
                return {
                    name: e.value,
                    data: this.state.json.trackingsGroupsBy_load_delta.map(list => list.map(e => ({ ...e, value: e.value.split(' ')[0] })).filter(e2 => e2.value === e.value)[0]).map(e => e ? e.count : 0)
                }
            })
        }
        timeline_chart2.options.xaxis.categories = this.state.json.datesForTimeline

        const timeline_chart3 = {
            ...commonOptionsTimeline,
            series: this.state.json.trackingsGroupsBy_click.map(e => {
                return {
                    name: e.value,
                    data: this.state.json.trackingsGroupsBy_click_delta.map(list => list.filter(e2 => e2.value === e.value)[0]).map(e => e ? e.count : 0)
                }
            })
        }
        timeline_chart3.options.xaxis.categories = this.state.json.datesForTimeline

        const timeline_chart4 = {
            ...commonOptionsTimeline,
            series: [
                {
                    name: 'Desktop',
                    data: this.state.json.trackingsGroupsBy_load_delta.map(list => list.reduce((acc, v) => {
                        const dim = v.value.split(' ')[1].split('x').map(e => parseInt(e))
                        if (dim[0] > dim[1])
                            acc += v.count
                        return acc
                    }, 0))
                },
                {
                    name: 'Mobile',
                    data: this.state.json.trackingsGroupsBy_load_delta.map(list => list.reduce((acc, v) => {
                        const dim = v.value.split(' ')[1].split('x').map(e => parseInt(e))
                        if (dim[0] < dim[1])
                            acc += v.count
                        return acc
                    }, 0))
                }
            ]
        }
        timeline_chart4.options.xaxis.categories = this.state.json.datesForTimeline

        const timeline_chart5 = {
            ...commonOptionsTimeline,
            series: trackingsGroupsBy_ads.map(e => {
                return {
                    name: e.value,
                    data: this.state.json.trackingsGroupsBy_ads_delta.map(list => list.map(e => ({ ...e, value: e.value.split(' ')[1] })).filter(e2 => e2.value === e.value)[0]).map(e => e ? e.count : 0)
                }
            })
        }
        timeline_chart5.options.xaxis.categories = this.state.json.datesForTimeline

        const timeline_chart6 = {
            ...commonOptionsTimeline,
            series: this.state.json.trackingsGroupsBy_cart.map(e => {
                return {
                    name: e.value,
                    data: this.state.json.trackingsGroupsBy_cart_delta.map(list => list.filter(e2 => e2.value === e.value)[0]).map(e => e ? e.count : 0)
                }
            })
        }
        timeline_chart6.options.xaxis.categories = this.state.json.datesForTimeline

        return (
            <div>
                <div>
                    <Grid container spacing={2} >
                        <Grid item xs={1}>
                            <Card style={{ padding: "10px" }} >
                                <h4 style={{ margin: "0px", marginBottom: "10px" }}>Time Total</h4>
                                <div>{this.state.json.trackingsGroupsBy_time.reduce((acc, v) => acc + v.count, 0) * 2}s</div>
                            </Card>
                        </Grid>
                        <Grid item xs={1}>
                            <Card style={{ padding: "10px" }} >
                                <h4 style={{ margin: "0px", marginBottom: "10px" }}>Ips</h4>
                                <div>{this.state.json.countDistinctIp[0].count}</div>
                            </Card>
                        </Grid>
                        <Grid item xs={1}>
                            <Card style={{ padding: "10px" }} >
                                <h4 style={{ margin: "0px", marginBottom: "10px" }}>Time / Ip</h4>
                                <div>{Math.floor(this.state.json.trackingsGroupsBy_time.reduce((acc, v) => acc + v.count, 0) * 2 / this.state.json.countDistinctIp[0].count)}s / ip</div>
                            </Card>
                        </Grid>
                    </Grid>
                    <div style={{ display: "flex", width: "100%" }}>
                        <div style={{ marginLeft: "20px" }}></div>
                        <div style={{ marginLeft: "20px" }}></div>
                    </div>
                    <div style={{ marginTop: "20px" }}></div>
                    <Grid container spacing={2} >
                        <Grid item xs={6}>
                            <Card style={{ padding: "10px" }} >
                                <h4 style={{ margin: "0px" }}>Time</h4>
                                <Chart className="custom-chart" options={chart0.options} series={chart0.series} type="donut" height="330" />
                            </Card>
                        </Grid>
                        <Grid item xs={6}>
                            <Card style={{ padding: "10px" }} >
                                <h4 style={{ margin: "0px" }}>Events</h4>
                                <Chart className="custom-chart" options={chart1.options} series={chart1.series} type="donut" height="330" />
                            </Card>
                        </Grid>
                        <Grid item xs={6}>
                            <Card style={{ padding: "10px" }} >
                                <h4 style={{ margin: "0px" }}>Load</h4>
                                <Chart className="custom-chart" options={chart2.options} series={chart2.series} type="donut" height="330" />
                            </Card>
                        </Grid>
                        <Grid item xs={6}>
                            <Card style={{ padding: "10px" }} >
                                <h4 style={{ margin: "0px" }}>Click</h4>
                                <Chart className="custom-chart" options={chart3.options} series={chart3.series} type="donut" height="330" />
                            </Card>
                        </Grid>
                        <Grid item xs={6}>
                            <Card style={{ padding: "10px" }} >
                                <h4 style={{ margin: "0px" }}>Desktop / Mobile</h4>
                                <Chart className="custom-chart" options={chart4.options} series={chart4.series} type="donut" height="330" />
                            </Card>
                        </Grid>
                        <Grid item xs={6}>
                            <Card style={{ padding: "10px" }} >
                                <h4 style={{ margin: "0px" }}>Ads</h4>
                                <Chart className="custom-chart" options={chart5.options} series={chart5.series} type="donut" height="330" />
                            </Card>
                        </Grid>
                        <Grid item xs={6}>
                            <Card style={{ padding: "10px" }} >
                                <h4 style={{ margin: "0px" }}>Cart</h4>
                                <Chart className="custom-chart" options={chart6.options} series={chart6.series} type="donut" height="330" />
                            </Card>
                        </Grid>
                    </Grid>
                    <div style={{ marginTop: "20px" }}></div>
                    <div style={{ marginTop: "20px" }}></div>
                    <div style={{ marginTop: "20px" }}></div>
                    <div style={{ marginTop: "20px" }}></div>
                    <div style={{ marginTop: "20px" }}></div>
                    <div style={{ marginTop: "20px" }}></div>
                    <Card style={{ padding: "10px" }} >
                        <h4 style={{ margin: "0px" }}>Timeline</h4>
                        <Chart className="custom-chart" options={timeline_chart0.options} series={timeline_chart0.series} type="area" height="265" />
                    </Card>
                    <div style={{ marginTop: "20px" }}></div>
                    <Card style={{ padding: "10px" }} >
                        <h4 style={{ margin: "0px" }}>Events Timeline</h4>
                        <Chart className="custom-chart" options={timeline_chart1.options} series={timeline_chart1.series} type="area" height="265" />
                    </Card>
                    <div style={{ marginTop: "20px" }}></div>
                    <Card style={{ padding: "10px" }} >
                        <h4 style={{ margin: "0px" }}>Load Timeline</h4>
                        <Chart className="custom-chart" options={timeline_chart2.options} series={timeline_chart2.series} type="area" height="265" />
                    </Card>
                    <div style={{ marginTop: "20px" }}></div>
                    <Card style={{ padding: "10px" }} >
                        <h4 style={{ margin: "0px" }}>Click Timeline</h4>
                        <Chart className="custom-chart" options={timeline_chart3.options} series={timeline_chart3.series} type="area" height="265" />
                    </Card>
                    <div style={{ marginTop: "20px" }}></div>
                    <Card style={{ padding: "10px" }} >
                        <h4 className="font-weight-bold">Desktop / Mobile Timeline</h4>
                        <Chart className="custom-chart" options={timeline_chart4.options} series={timeline_chart4.series} type="area" height="265" />
                    </Card>
                    <div style={{ marginTop: "20px" }}></div>
                    <Card style={{ padding: "10px" }} >
                        <h4 className="font-weight-bold">Ads Timeline</h4>
                        <Chart className="custom-chart" options={timeline_chart5.options} series={timeline_chart5.series} type="area" height="265" />
                    </Card>
                    <div style={{ marginTop: "20px" }}></div>
                    <Card style={{ padding: "10px" }} >
                        <h4 className="font-weight-bold">Cart Timeline</h4>
                        <Chart className="custom-chart" options={timeline_chart6.options} series={timeline_chart6.series} type="area" height="265" />
                    </Card>
                </div>
            </div >
        )
    }

    render() {
        return (
            <div>
                <div>
                    <FormControl variant="filled" style={{ margin: "20px", marginLeft: "0px", minWidth: "200px" }}>
                        <InputLabel>Ago</InputLabel>
                        <Select
                            value={this.state.ago}
                            onChange={(event) => {
                                localStorage.setItem('ago', event.target.value)
                                this.setState({ ago: event.target.value, json: null }, () => this.call())
                            }}
                        >
                            {
                                this.agos.map((elem, index) => <MenuItem value={index}>{elem[1]}</MenuItem>)
                            }
                        </Select>
                    </FormControl>
                    {
                        this.state.ago === 0 && (
                            <TextField
                                style={{ margin: "20px" }}
                                id="datetime-local"
                                label="Start"
                                type="datetime-local"
                                value={new Date(this.state.start.getTime() - new Date().getTimezoneOffset() * 60 * 1000).toISOString().substring(0, '2021-05-16T19:53'.length)}
                                onChange={(event) => {
                                    this.setState({ start: new Date(new Date(event.target.value).getTime()), json: null }, () => this.call())
                                }}
                                // defaultValue="2017-05-24T10:30"
                                // className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        )
                    }
                    {
                        this.state.ago === 0 && (
                            <TextField
                                style={{ margin: "20px" }}
                                id="datetime-local"
                                label="End"
                                type="datetime-local"
                                value={new Date(this.state.end.getTime() - new Date().getTimezoneOffset() * 60 * 1000).toISOString().substring(0, '2021-05-16T19:53'.length)}
                                onChange={(event) => {
                                    this.setState({ end: new Date(new Date(event.target.value).getTime()), json: null }, () => this.call())
                                }}
                                // defaultValue="2017-05-24T10:30"
                                // className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        )
                    }
                    {
                        this.state.websites && (
                            <FormControl variant="filled" style={{ margin: "20px", marginLeft: "0px", minWidth: "200px" }}>
                                <InputLabel>Website</InputLabel>
                                <Select
                                    value={this.state.website}
                                    onChange={(event) => {
                                        this.setState({ website: event.target.value, json: null }, () => this.call())
                                    }}
                                >
                                    {
                                        this.state.websites.map((elem, index) => <MenuItem value={elem}>{elem.ndd}</MenuItem>)
                                    }
                                </Select>
                            </FormControl>
                        )
                    }
                </div>
                {this.renderData()}
            </div>
        )
    }
}

export default Graph
