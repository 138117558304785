
import { Component } from 'react'
import {
    fetchUtils,
} from 'react-admin'
import { stringify } from 'query-string'
import { LineChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Line } from 'recharts'
import { PieChart, Pie, Sector, Cell } from 'recharts'
import { AreaChart, Area } from 'recharts'
import { BarChart, Bar } from 'recharts'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import Chart from "react-apexcharts";

import dataProvider from '../dataProvider'
import authProvider from '../authProvider'

const { fetchJson } = fetchUtils

const API_URL = process.env.REACT_APP_API_HOSTNAME

class Graph extends Component {

    constructor(props) {
        super(props)
        this.state = {
            json: null,
            ago: !localStorage.getItem('ago') ? 1 : parseInt(localStorage.getItem('ago')),
            start: new Date(Date.now() - 24 * 3600 * 1000),
            end: new Date(),
            types: [],
            users: [],
            type: undefined,
            user: undefined,
        }
        this.agos = [
            [null, "None"],
            [5 * 60, "5 min ago"],
            [15 * 60, "15 min ago"],
            [30 * 60, "30 min ago"],
            [3600, "1 hour ago"],
            [3 * 3600, "3 hour ago"],
            [6 * 3600, "6 hour ago"],
            [12 * 3600, "12 hour ago"],
            [24 * 3600, "24 hour ago"],
            [48 * 3600, "48 hour ago"],
            [72 * 3600, "72 hour ago"],
            [7 * 24 * 3600, "7 days ago"],
            [30 * 24 * 3600, "30 days ago"],
        ]
    }

    componentDidMount() {
        authProvider.checkAuth().then(() => {
            dataProvider.getList('Website', { pagination: { page: 1, perPage: 1000 }, sort: {} }).then((res) => {
                this.setState({ websites: res.data }, () => {
                    const ago = new URLSearchParams(window.location.search).get("ago")
                    if (ago) {
                        this.setState({ ago }, () => this.call())
                    } else {
                        this.call()
                    }
                })
            })
        }).catch(() => {
            authProvider.logout().then(() => window.location = '/#/login')
        })
    }

    call() {
        const query = {}
        if (this.state.ago) {
            query.start = new Date(Date.now() - this.agos[this.state.ago][0] * 1000).toISOString()
        } else {
            query.start = this.state.start.toISOString()
            query.end = this.state.end.toISOString()
        }
        dataProvider.customs.gainGraph(query.start, query.end, this.state.website?.id).then((res) => {
            this.setState({ json: res })
        })
    }

    renderData() {
        if (!this.state.json)
            return <></>
        const formatXDate = (d) => {
            d = new Date(d)
            return `${d.getDate()},${d.getHours()}:${d.getMinutes()}`
        }
        const colors = [
            "#CC0000",
            "#CC7C00",
            "#CCCC00",
            "#00CC00",
            "#0000CC",
            "#2E2C5C",
            "#8C00CC",

            "#DD0000",
            "#DD7C00",
            "#DDDD00",
            "#00DD00",
            "#0000DD",
            "#2E2C5C",
            "#8C00DD",

            "#EE0000",
            "#EE7C00",
            "#EEEE00",
            "#00EE00",
            "#0000EE",
            "#2E2C5C",
            "#8C00EE",
        ]

        const commonOptions = {
            chart: {
                height: 330,
                type: 'donut',

            },
            colors: colors,
            plotOptions: {
                pie: {
                    startAngle: -90,
                    endAngle: 270,
                    donut: {
                        size: '80%',
                        labels: {
                            show: true,
                            total: {
                                show: true,
                                color: '#BCC1C8',
                                fontSize: '18px',
                                fontFamily: 'DM Sans',
                                fontWeight: 600,
                            },
                            value: {
                                show: true,
                                fontSize: '25px',
                                fontFamily: 'DM Sans',
                                fontWeight: 700,
                                color: '#8F9FBC',

                            },

                        }
                    }
                }
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                lineCap: 'round'
            },
            grid: {
                padding: {

                    bottom: 0,
                }
            },
            legend: {
                position: 'bottom',
                offsetY: 8,
                show: true,
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        height: 268
                    }
                }
            }]
        }

        const commonOptionsTimeline = {
            options: {

                colors,
                chart: {
                    fontFamily: 'DM Sans',
                    toolbar: {
                        show: false,
                    },
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'smooth'
                },
                fill: {
                    type: 'gradient',
                    gradient: {
                        shade: 'light',
                        type: "vertical",
                        shadeIntensity: 0.5,
                        inverseColors: false,
                        opacityFrom: .8,
                        opacityTo: .2,
                        stops: [0, 50, 100],
                        colorStops: []
                    }
                },
                grid: {
                    xaxis: {
                        lines: {
                            show: false
                        }
                    },
                    yaxis: {
                        lines: {
                            show: false
                        }
                    }
                },
                yaxis: {
                    labels: {
                        offsetY: 0,
                        minWidth: 20,
                        maxWidth: 20
                    },
                },
                xaxis: {
                    type: 'datetime',
                    labels: {
                        minHeight: 20,
                        maxHeight: 20
                    },
                },
                tooltip: {
                    x: {
                        format: 'dd/MM/yy HH:mm'
                    },
                },
            },
        }

        const chart1 = {
            options: {
                ...commonOptions,
                labels: this.state.json.gain.map(e => e.value),
            },
            series: this.state.json.gain.map(e => e.count),
        }

        const timeline_chart1 = {
            ...commonOptionsTimeline,
            series: this.state.json.gain.map(e => {
                return {
                    name: e.value,
                    data: this.state.json.gain_delta.map(list => list.filter(e2 => e2.value === e.value)[0]).map(e => e ? e.count : 0)
                }
            })
        }
        console.log(timeline_chart1.series)
        timeline_chart1.options.xaxis.categories = this.state.json.datesForTimeline

        return (
            <div>
                <div>
                    <div style={{ display: "flex", width: "100%" }}>
                        <div style={{ marginLeft: "20px" }}></div>
                        <div style={{ marginLeft: "20px" }}></div>
                    </div>
                    <div style={{ marginTop: "20px" }}></div>
                    <Grid container spacing={2} >
                        <Grid item xs={6}>
                            <Card style={{ padding: "10px" }} >
                                <h4 style={{ margin: "0px" }}>Gain</h4>
                                <Chart className="custom-chart" options={chart1.options} series={chart1.series} type="donut" height="330" />
                            </Card>
                        </Grid>
                    </Grid>
                    <div style={{ marginTop: "20px" }}></div>
                    <div style={{ marginTop: "20px" }}></div>
                    <div style={{ marginTop: "20px" }}></div>
                    <div style={{ marginTop: "20px" }}></div>
                    <div style={{ marginTop: "20px" }}></div>
                    <div style={{ marginTop: "20px" }}></div>
                    <Card style={{ padding: "10px" }} >
                        <h4 style={{ margin: "0px" }}>Gain Timeline</h4>
                        <Chart className="custom-chart" options={timeline_chart1.options} series={timeline_chart1.series} type="area" height="265" />
                    </Card>
                    <div style={{ marginTop: "20px" }}></div>
                </div>
            </div >
        )
    }

    render() {
        return (
            <div>
                <div>
                    <FormControl variant="filled" style={{ margin: "20px", marginLeft: "0px", minWidth: "200px" }}>
                        <InputLabel>Ago</InputLabel>
                        <Select
                            value={this.state.ago}
                            onChange={(event) => {
                                localStorage.setItem('ago', event.target.value)
                                this.setState({ ago: event.target.value, json: null }, () => this.call())
                            }}
                        >
                            {
                                this.agos.map((elem, index) => <MenuItem value={index}>{elem[1]}</MenuItem>)
                            }
                        </Select>
                    </FormControl>
                    {
                        this.state.ago === 0 && (
                            <TextField
                                style={{ margin: "20px" }}
                                id="datetime-local"
                                label="Start"
                                type="datetime-local"
                                value={new Date(this.state.start.getTime() - new Date().getTimezoneOffset() * 60 * 1000).toISOString().substring(0, '2021-05-16T19:53'.length)}
                                onChange={(event) => {
                                    this.setState({ start: new Date(new Date(event.target.value).getTime()), json: null }, () => this.call())
                                }}
                                // defaultValue="2017-05-24T10:30"
                                // className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        )
                    }
                    {
                        this.state.ago === 0 && (
                            <TextField
                                style={{ margin: "20px" }}
                                id="datetime-local"
                                label="End"
                                type="datetime-local"
                                value={new Date(this.state.end.getTime() - new Date().getTimezoneOffset() * 60 * 1000).toISOString().substring(0, '2021-05-16T19:53'.length)}
                                onChange={(event) => {
                                    this.setState({ end: new Date(new Date(event.target.value).getTime()), json: null }, () => this.call())
                                }}
                                // defaultValue="2017-05-24T10:30"
                                // className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        )
                    }
                                        {
                        this.state.websites && (
                            <FormControl variant="filled" style={{ margin: "20px", marginLeft: "0px", minWidth: "200px" }}>
                                <InputLabel>Website</InputLabel>
                                <Select
                                    value={this.state.website}
                                    onChange={(event) => {
                                        this.setState({ website: event.target.value, json: null }, () => this.call())
                                    }}
                                >
                                    <MenuItem value={undefined}>{"NONE"}</MenuItem>
                                    {
                                        this.state.websites.map((elem, index) => <MenuItem value={elem}>{elem.ndd}</MenuItem>)
                                    }
                                </Select>
                            </FormControl>
                        )
                    }
                </div>
                {this.renderData()}
            </div>
        )
    }
}

export default Graph
