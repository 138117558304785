
import React from 'react'
import {
    List,
    Datagrid,
    Edit,
    Create,
    SimpleForm,
    DateField,
    TextField,
    ArrayField,
    SingleFieldList,
    EditButton,
    TextInput,
    DateInput,
    Responsive,
    SimpleList,
    SimpleShowLayout,
    ShowButton,
    Show,
    UrlField,
    SelectInput,
    Filter,
    useListContext,
    TopToolbar,
    CreateButton,
    ExportButton,
    Pagination,
    Button,
    ChipField,
    required,
    sanitizeListRestProps,
    SaveButton,
    DeleteButton,
    Toolbar,
    ArrayInput,
    SimpleFormIterator,
    NumberInput,
    ReferenceInput,
    AutocompleteInput,
    ReferenceArrayInput,
    SelectArrayInput,
    ImageField,
    BooleanField,
    BooleanInput,
    ReferenceField
} from 'react-admin'
import { cloneElement, useMemo } from 'react'
import PropTypes from 'prop-types'
import BookIcon from '@material-ui/icons/Category'
import ShortUrlField from '../Customs/ShortUrlField'
import MultipleField from '../Customs/MultipleField'
import MultipleInput from '../Customs/MultipleInput'
import DeleteAllButton from '../Customs/DeleteAllButton'
import ImportButton from '../Customs/ImportButton'
import ApiSelectInput from '../Customs/ApiSelectInput'
import GalleryInput from '../Customs/GalleryInput'


const API_URL = process.env.REACT_APP_API_HOSTNAME

export const BannerIcon = BookIcon

const Banner_Filter = (props) => (
    <Filter {...props}>
        <TextInput source="path" options={{ maxLength: 1024 }} />
        <ReferenceInput source="website" reference="Website" perPage={10000} >
            <AutocompleteInput optionText="ndd" />
        </ReferenceInput>
    </Filter>
)

const ListActions_Store = {}

const ListActions = (props) => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props
    let {
        currentSort,
        resource,
        displayedFilters,
        filterValues,
        hasCreate,
        basePath,
        selectedIds,
        showFilter,
        total,
    } = useListContext()
    ListActions_Store.filterValues = filterValues
    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
            <CreateButton basePath={basePath} />
        </TopToolbar>
    )
}

export const BannerList = (props) => (
    <List {...props} filters={<Banner_Filter />} actions={<ListActions />} perPage={10}>
        <Responsive
            small={
                <SimpleList linkType="show"
                    primaryText={record => record ? record.key : ''}
                />
            }
            medium={
                <Datagrid>
                    <TextField source="id" sortable={true} />
                    <TextField source="path" sortable={false} />
                    <TextField source="label" sortable={false} />
                    <ImageField source="media_url" sortable={false} />
                    <ReferenceField source="website" reference="Website" >
                        <TextField source="ndd" />
                    </ReferenceField>
                    <EditButton />
                    <ShowButton />
                </Datagrid>
            }
        />
    </List>
)

export const BannerShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="path" />
            <TextField source="label" />
            <ImageField source="media_url" />
            <ReferenceField source="website" reference="Website" >
                <TextField source="ndd" />
            </ReferenceField>
            <DateField source="createdAt" showTime={true} />
            <DateField source="updatedAt" showTime={true} />
        </SimpleShowLayout>
    </Show>
)

const CustomToolBar = props => <Toolbar {...props} ><SaveButton /></Toolbar>

export const BannerEdit = (props) => (
    <Edit {...props} undoable={false} toolbar={<CustomToolBar />} >
        <SimpleForm redirect="show">
            <TextInput source="path" validate={required()} />
            <TextInput source="label" />
            <GalleryInput source="media" />

            {/* <ReferenceInput source="media" reference="Media" validate={required()} perPage={10000} >
                <AutocompleteInput optionText="s3_key" />
            </ReferenceInput> */}
            <ReferenceInput source="website" reference="Website" perPage={10000} >
                <AutocompleteInput optionText="ndd" />
            </ReferenceInput>
        </SimpleForm>
    </Edit>
)

export const BannerCreate = (props) => (
    <Create {...props}>
        <SimpleForm redirect="show">
            <TextInput source="path" validate={required()} />
            <TextInput source="label" />
            <GalleryInput source="media" />

            {/* <ReferenceInput source="media" reference="Media" validate={required()} perPage={10000} >
                <AutocompleteInput optionText="s3_key" />
            </ReferenceInput> */}
            <ReferenceInput source="website" reference="Website" perPage={10000} >
                <AutocompleteInput optionText="ndd" />
            </ReferenceInput>
        </SimpleForm>
    </Create>
)
