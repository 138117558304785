
import React from 'react'
import {
    List,
    Datagrid,
    Edit,
    Create,
    SimpleForm,
    DateField,
    TextField,
    ArrayField,
    SingleFieldList,
    EditButton,
    TextInput,
    DateInput,
    Responsive,
    SimpleList,
    SimpleShowLayout,
    ShowButton,
    Show,
    UrlField,
    SelectInput,
    Filter,
    useListContext,
    TopToolbar,
    CreateButton,
    ExportButton,
    Pagination,
    Button,
    ChipField,
    required,
    sanitizeListRestProps,
    SaveButton,
    DeleteButton,
    Toolbar,
    ArrayInput,
    SimpleFormIterator,
    NumberInput,
    ReferenceInput,
    ReferenceArrayInput,
    SelectArrayInput,
    ImageField,
    ReferenceField
} from 'react-admin'
import { cloneElement, useMemo } from 'react'
import PropTypes from 'prop-types'
import BookIcon from '@material-ui/icons/ShoppingCart'
import PfdCommandButton from '../Customs/PfdCommandButton'
import ShortUrlField from '../Customs/ShortUrlField'
import MultipleField from '../Customs/MultipleField'
import MultipleInput from '../Customs/MultipleInput'
import DeleteAllButton from '../Customs/DeleteAllButton'
import ImportButton from '../Customs/ImportButton'
import ApiSelectInput from '../Customs/ApiSelectInput'

const API_URL = process.env.REACT_APP_API_HOSTNAME

export const CommandIcon = BookIcon


const Command_Filter = (props) => (
    <Filter {...props}>
        <TextInput source="ref" />
        <TextInput source="status" />
        <TextInput source="anouser" />
        <TextInput source="amount" />
        <TextInput source="firstname" />
        <TextInput source="lastname" />
        <TextInput source="address" />
        <TextInput source="phone" />
        <TextInput source="email" />
        <TextInput source="city" />
        <TextInput source="postcode" />
        <TextInput source="note" />
        <TextInput source="item" />
    </Filter>
)

const ListActions_Store = {}

const ListActions = (props) => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props
    let {
        currentSort,
        resource,
        displayedFilters,
        filterValues,
        hasCreate,
        basePath,
        selectedIds,
        showFilter,
        total,
    } = useListContext()
    ListActions_Store.filterValues = filterValues
    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
        </TopToolbar>
    )
}

export const CommandList = (props) => (
    <List {...props} filters={<Command_Filter />} actions={<ListActions />} perPage={10} sort={{ field: 'id', order: 'DESC' }}>
        <Responsive
            small={
                <SimpleList linkType="show"
                    primaryText={record => record ? record.ref : ''}
                />
            }
            medium={
                <Datagrid>
                    {/* <TextField source="id" sortable={true} /> */}
                    <TextField source="ref" sortable={false} />
                    {/* <TextField source="ip" sortable={false} /> */}
                    <TextField source="status" sortable={false} />
                    <TextField source="amount" sortable={true} />

                    <TextField source="firstname" sortable={true} />
                    <TextField source="lastname" sortable={true} />
                    {/* <TextField source="address" sortable={false} /> */}
                    <TextField source="phone" sortable={false} />
                    <TextField source="email" sortable={false} />
                    {/* <TextField source="city" sortable={false} />
                    <TextField source="postcode" sortable={false} /> */}
                    <ReferenceField source="website" reference="Website" >
                        <TextField source="ndd" />
                    </ReferenceField>
                    <DateField source="updatedAt" showTime={true} sortable={true} />
                    <ShowButton />
                    <PfdCommandButton />
                    <EditButton />
                </Datagrid>
            }
        />
    </List>
)



/*
`ref` VARCHAR(128) NOT NULL UNIQUE,
`status` TEXT,
`anouser` INT UNIQUE,
-- `user` INT UNIQUE,
`amount` INT,

`firstname` TEXT,
`lastname` TEXT,
`address` TEXT,
`phone` TEXT,
`email` TEXT,
`city` TEXT,
`postcode` TEXT,
`note` TEXT,
`items` TEXT,

`stripe_customer_id` VARCHAR(64),
`stripe_customer_id` VARCHAR(128),

*/

export const CommandShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="ref" />
            <TextField source="ip" />
            <TextField source="status" />
            <TextField source="amount" />
            <TextField source="firstname" />
            <TextField source="lastname" />
            <TextField source="address" />
            <TextField source="phone" />
            <TextField source="email" />
            <TextField source="city" />
            <TextField source="postcode" />
            <TextField source="stripe_customer_id" />
            <TextField source="stripe_session_id" />
            <TextField source="note" />
            <TextField source="private_note" component="pre" />
            <ArrayField source="items">
                <Datagrid>
                    <TextField source="id" />
                    <TextField source="item.title" />
                    <ImageField source="item.preview_media_url" />
                    <TextField source="quantity" />
                    <TextField source="attributes" />
                </Datagrid>
            </ArrayField>
            <ReferenceField source="website" reference="Website" >
                <TextField source="ndd" />
            </ReferenceField>
            <DateField source="createdAt" showTime={true} />
            <DateField source="updatedAt" showTime={true} />
        </SimpleShowLayout>
    </Show>
)

const CustomToolBar = props => <Toolbar {...props} ><SaveButton /></Toolbar>

export const CommandEdit = (props) => (
    <Edit {...props} undoable={false} toolbar={<CustomToolBar />} >
        <SimpleForm redirect="show">
            <TextInput source="status" validate={required()} />
            <TextInput source="firstname" validate={required()} />
            <TextInput source="lastname" validate={required()} />
            <TextInput source="address" validate={required()} />
            <TextInput source="phone" validate={required()} />
            <TextInput source="email" validate={required()} />
            <TextInput source="city" validate={required()} />
            <TextInput source="postcode" validate={required()} />
            <TextInput source="private_note" multiline fullWidth />
        </SimpleForm>
    </Edit>
)
