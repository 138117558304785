
import React from 'react'
import {
    List,
    Datagrid,
    Edit,
    Create,
    SimpleForm,
    DateField,
    TextField,
    ArrayField,
    SingleFieldList,
    EditButton,
    TextInput,
    DateInput,
    Responsive,
    SimpleList,
    SimpleShowLayout,
    ShowButton,
    Show,
    UrlField,
    SelectInput,
    Filter,
    useListContext,
    TopToolbar,
    CreateButton,
    ExportButton,
    Pagination,
    Button,
    ChipField,
    required,
    sanitizeListRestProps,
    SaveButton,
    DeleteButton,
    Toolbar,
    ArrayInput,
    SimpleFormIterator,
    NumberInput,
    ReferenceInput,
    AutocompleteInput,
    ReferenceArrayInput,
    SelectArrayInput,
    ImageField,
    BooleanField,
    BooleanInput,
    ReferenceField
} from 'react-admin'
import { cloneElement, useMemo } from 'react'
import PropTypes from 'prop-types'
import BookIcon from '@material-ui/icons/TrackChanges'
import ShortUrlField from '../Customs/ShortUrlField'
import MultipleField from '../Customs/MultipleField'
import MultipleInput from '../Customs/MultipleInput'
import DeleteAllButton from '../Customs/DeleteAllFastButton'
import ImportButton from '../Customs/ImportButton'
import ApiSelectInput from '../Customs/ApiSelectInput'

const API_URL = process.env.REACT_APP_API_HOSTNAME

export const TrackingIcon = BookIcon

const Tracking_Filter = (props) => (
    <Filter {...props}>
        <TextInput source="ip" options={{ maxLength: 1024 }} />
        <TextInput source="type" options={{ maxLength: 1024 }} />
        <TextInput source="noType" options={{ maxLength: 1024 }} />
        <TextInput source="tag" options={{ maxLength: 1024 }} />
        <ReferenceInput source="website" reference="Website" perPage={10000} >
            <AutocompleteInput optionText="ndd" />
        </ReferenceInput>
    </Filter>
)

const ListActions_Store = {}

const ListActions = (props) => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props
    let {
        currentSort,
        resource,
        displayedFilters,
        filterValues,
        hasCreate,
        basePath,
        selectedIds,
        showFilter,
        total,
    } = useListContext()
    ListActions_Store.filterValues = filterValues
    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
            {/* <CreateButton basePath={basePath} /> */}
            <DeleteAllButton basePath={basePath} resource={resource} />
        </TopToolbar>
    )
}

export const TrackingList = (props) => (
    <List {...props} filters={<Tracking_Filter />} actions={<ListActions />} perPage={10}>
        <Responsive
            small={
                <SimpleList linkType="show"
                    primaryText={record => record ? record.ip + ' ' + record.type : ''}
                />
            }
            medium={
                <Datagrid>
                    <TextField source="id" sortable={false} />
                    <TextField source="ip" sortable={false} />
                    <TextField source="type" sortable={false} />
                    <TextField source="tag" sortable={false} />
                    <ReferenceField source="website" reference="Website" >
                        <TextField source="ndd" />
                    </ReferenceField>
                    <DateField source="createdAt" showTime={true} sortable={false} />
                    <EditButton />
                    <ShowButton />
                </Datagrid>
            }
        />
    </List>
)

export const TrackingShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="ip" />
            <TextField source="type" />
            <TextField source="tag" />
            <ReferenceField source="website" reference="Website" >
                <TextField source="ndd" />
            </ReferenceField>
            <DateField source="createdAt" showTime={true} />
        </SimpleShowLayout>
    </Show>
)
