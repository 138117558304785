
import React from 'react'
import {
    List,
    Datagrid,
    Edit,
    Create,
    SimpleForm,
    DateField,
    TextField,
    ArrayField,
    SingleFieldList,
    EditButton,
    TextInput,
    DateInput,
    Responsive,
    SimpleList,
    SimpleShowLayout,
    ShowButton,
    Show,
    UrlField,
    SelectInput,
    Filter,
    useListContext,
    TopToolbar,
    CreateButton,
    ExportButton,
    Pagination,
    Button,
    ChipField,
    required,
    sanitizeListRestProps,
    SaveButton,
    DeleteButton,
    Toolbar,
    NumberInput,
    FileInput,
    FileField,
    translate,
    ImageField,
    ReferenceField,
    ReferenceInput,
    RichTextField,
    BooleanField,
    BooleanInput,
    AutocompleteInput,
} from 'react-admin'
import { cloneElement, useMemo } from 'react'
import PropTypes from 'prop-types'
import BookIcon from '@material-ui/icons/BookOutlined'
import RichTextInput from 'ra-input-rich-text';
import OptionalImageField from '../Customs/OptionalImageField'
import dataProvider from '../dataProvider'
import JsonInput from '../Customs/JsonInput'
import CodeField from '../Customs/CodeField'

const API_URL = process.env.REACT_APP_API_HOSTNAME

export const PageIcon = BookIcon

const Page_Filter = (props) => (
    <Filter {...props}>
        <ReferenceInput source="website" reference="Website" perPage={10000} >
            <AutocompleteInput optionText="ndd" />
        </ReferenceInput>
    </Filter>
)

const ListActions_Store = {}

const ListActions = (props) => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props
    let {
        currentSort,
        resource,
        displayedFilters,
        filterValues,
        hasCreate,
        basePath,
        selectedIds,
        showFilter,
        total,
    } = useListContext()
    ListActions_Store.filterValues = filterValues
    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
            <CreateButton basePath={basePath} />
        </TopToolbar>
    )
}

export const PageList = (props) => (
    <List {...props} filters={<Page_Filter />} actions={<ListActions />} perPage={10}>
        <Responsive
            small={
                <SimpleList linkType="show"
                    primaryText={record => record ? record.id : ''}
                />
            }
            medium={
                <Datagrid>
                    <TextField source="slug" sortable={false} />
                    <ReferenceField source="website" reference="Website" >
                        <TextField source="ndd" />
                    </ReferenceField>
                    <DateField source="createdAt" showTime={true} sortable={true} />
                    <DateField source="updatedAt" showTime={true} sortable={true} />
                    <EditButton />
                    <ShowButton />
                </Datagrid>
            }
        />
    </List>
)


export const PageShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="slug" />
            <ReferenceField source="website" reference="Website" >
                <TextField source="ndd" />
            </ReferenceField>
            <CodeField source="content" />
            <DateField source="createdAt" showTime={true} />
            <DateField source="updatedAt" showTime={true} />
        </SimpleShowLayout>
    </Show>
)

const CustomToolBar = props => <Toolbar {...props} ><SaveButton /></Toolbar>

const validateJson = async (value, record) => {
    try {
        JSON.parse(value)
    } catch (e) {
        return "JSON Invalid"
    }
}

export const PageEdit = (props) => (
    <Edit {...props} undoable={false} toolbar={<CustomToolBar />} >
        <SimpleForm redirect="show">
            <TextInput source="slug" validate={[]} />
            <ReferenceInput source="website" reference="Website" validate={required()} perPage={10000} >
                <AutocompleteInput optionText="ndd" />
            </ReferenceInput>
            <JsonInput source="content" validate={[required(), validateJson]} />
        </SimpleForm>
    </Edit>
)

export const PageCreate = (props) => (
    <Create {...props}>
        <SimpleForm redirect="show">
            <TextInput source="slug" validate={[]} />
            <ReferenceInput source="website" reference="Website" validate={required()} perPage={10000} >
                <AutocompleteInput optionText="ndd" />
            </ReferenceInput>
            <JsonInput source="content" validate={[required(), validateJson]} />
        </SimpleForm>
    </Create>
)

