
import { Component } from 'react'
import {
    fetchUtils,
} from 'react-admin'
import { stringify } from 'query-string'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import MenuItem from '@material-ui/core/MenuItem'
import Chart from "react-apexcharts";
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import dataProvider from '../dataProvider'
import authProvider from '../authProvider'

const { fetchJson } = fetchUtils

const API_URL = process.env.REACT_APP_API_HOSTNAME

class SearchOpenai extends Component {

    constructor(props) {
        super(props)
        this.state = {
        }
    }

    componentDidMount() {
        authProvider.checkAuth().then(() => {
            dataProvider.getList("Prompt", { pagination: { page: 1, perPage: 10000 }, sort: {} }).then(json => {
                this.setState({ prompts: json.data })
            })
        }).catch(() => {
            authProvider.logout().then(() => window.location = '/#/login')
        })
    }

    call() {
        this.setState({ loading: true, data: null }, () => {
            dataProvider.customs.openaiCompletion(this.state).then((res) => {
                let data = res.data.choices[0].text
                if (data.startsWith('\n\n'))
                    data = data.substring(2)
                this.setState({ data, loading: false })
            })
        })
    }

    renderData() {
        if (!this.state.data)
            return <></>

        return (
            <div>
                {/* <div>{JSON.stringify(this.state.data)}</div> */}
                <div style={{ display: "flex" }}>
                    <TextField
                        variant='outlined'
                        style={{ margin: "20px", width: "800px" }}
                        label="Result"
                        multiline
                        rows={10}
                        value={this.state.data}
                        onChange={(e) => {
                            this.setState({ data: e.target.value })
                        }}
                    />
                    <div>
                        Words Count: {this.state.data.split('\n').join(' ').split(' ').filter(e => !!e).length}
                    </div>
                </div>
                <div style={{ display: "flex", marginTop: "20px" }}>
                    <TextField
                        variant='outlined'
                        style={{ marginRight: "20px" }}
                        label="Text Name"
                        value={this.state.name}
                        onChange={(e) => {
                            this.setState({ name: e.target.value })
                        }}
                    />
                    <Button
                        variant='outlined'
                        style={{ marginRight: "20px" }}
                        disabled={!this.state.name}
                        onClick={() => dataProvider.create('Text', { data: { name: this.state.name, content: this.state.data } }).then(json => {
                            window.location = `/#/Text/${json.data.id}/show`
                        })}
                    >
                        Save Text
                    </Button>
                    {
                        !this.state.promptSaved && !this.state.choice && (
                            <Button
                                variant='outlined'
                                disabled={!this.state.name}
                                onClick={() => dataProvider.create('Prompt', { data: { name: this.state.name, prompt: this.state.prompt, max_tokens: this.state.max_tokens, temperature: this.state.temperature } }).then(json => {
                                    this.setState({ promptSaved: true })
                                })}
                            >
                                Save Prompt
                            </Button>
                        )
                    }
                </div>
            </div>
        );

    }

    render() {
        return (
            <div>
                <div style={{ display: "flex" }}>
                    {/* <Autocomplete
                        id="combo-box-demo"
                        options={this.state.prompts}
                        getOptionLabel={(option) => option.fullname}
                        style={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Prompt" variant="outlined" />}
                    /> */}
                    <FormControl style={{ width: "400px" }}>
                        <InputLabel id="select-label">Prompts</InputLabel>
                        <Select
                            labelId="select-label"
                            id="select-label"
                            value={this.state.choice}
                            onChange={(e) => this.setState({ ...e.target.value, choice: e.target.value })}
                        >
                            {
                                (this.state.prompts || []).map(e => <MenuItem value={e}>{e.fullname}</MenuItem>)
                            }
                        </Select>
                    </FormControl>
                </div>
                <div style={{ display: "flex" }}>
                    <TextField
                        variant='outlined'
                        style={{ margin: "20px", width: "800px" }}
                        label="Prompt"
                        multiline
                        rows={10}
                        value={this.state.prompt}
                        onChange={(e) => {
                            this.setState({ prompt: e.target.value })
                        }}
                    />
                    <TextField
                        variant='outlined'
                        style={{ margin: "20px" }}
                        label="Max Tokens"
                        type='number'
                        value={this.state.max_tokens}
                        onChange={(e) => {
                            this.setState({ max_tokens: e.target.value })
                        }}
                    />
                    <TextField
                        variant='outlined'
                        style={{ margin: "20px" }}
                        label="Temperature"
                        type='number'
                        value={this.state.temperature}
                        onChange={(e) => {
                            this.setState({ temperature: e.target.value })
                        }}
                    />
                    <Button
                        disabled={this.state.loading}
                        onClick={() => this.call()}
                    >
                        Generate
                    </Button>
                </div>
                <div>
                    {this.renderData()}
                </div>
            </div>
        )
    }
}

export default SearchOpenai
