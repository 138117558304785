
import React, { Fragment } from 'react'
import {
    List,
    Datagrid,
    Edit,
    Create,
    SimpleForm,
    DateField,
    TextField,
    ArrayField,
    SingleFieldList,
    EditButton,
    TextInput,
    DateInput,
    Responsive,
    SimpleList,
    SimpleShowLayout,
    ShowButton,
    Show,
    UrlField,
    SelectInput,
    Filter,
    useListContext,
    TopToolbar,
    CreateButton,
    ExportButton,
    Pagination,
    Button,
    ChipField,
    required,
    sanitizeListRestProps,
    SaveButton,
    DeleteButton,
    Toolbar,
    NumberInput,
    BulkDeleteButton,
    ReferenceInput,
    BooleanField,
    BooleanInput,
    AutocompleteInput,
    ReferenceField,
} from 'react-admin'
import { cloneElement, useMemo } from 'react'
import PropTypes from 'prop-types'
import BookIcon from '@material-ui/icons/AccountBoxOutlined'
import OptionalImageField from '../Customs/OptionalImageField'
import MultipleField from '../Customs/MultipleField'
import MultipleInput from '../Customs/MultipleInput'
import DeleteAllButton from '../Customs/DeleteAllButton'
import ImportButton from '../Customs/ImportButton'
import UpdateInpiButton from '../Customs/UpdateInpiButton'
import ApiSelectInput from '../Customs/ApiSelectInput'
import GalleryInput from '../Customs/GalleryInput'

const API_URL = process.env.REACT_APP_API_HOSTNAME

export const WebsiteIcon = BookIcon

const Website_Filter = (props) => (
    <Filter {...props}>
        <TextInput source="login" options={{ maxLength: 1024 }} />
        <TextInput source="email" options={{ maxLength: 1024 }} />
    </Filter>
)

const ListActions_Store = {}

const ListActions = (props) => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props
    let {
        currentSort,
        resource,
        displayedFilters,
        filterValues,
        hasCreate,
        basePath,
        selectedIds,
        showFilter,
        total,
    } = useListContext()
    ListActions_Store.filterValues = filterValues
    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
            <CreateButton basePath={basePath} />
        </TopToolbar>
    )
}

export const WebsiteList = (props) => (
    <List {...props} filters={<Website_Filter />} actions={<ListActions />} perPage={50} bulkActionButtons={false} >
        <Responsive
            small={
                <SimpleList linkType="show"
                    primaryText={record => record ? record.email : ''}
                />
            }
            medium={
                <Datagrid>
                    <TextField source="id" sortable={true} />
                    <TextField source="email" sortable={true} />
                    <TextField source="ndd" sortable={false} />
                    <DateField source="createdAt" showTime={true} sortable={true} />
                    <DateField source="updatedAt" showTime={true} sortable={true} />
                    <EditButton />
                    <ShowButton />
                </Datagrid>
            }
        />
    </List>
)


export const WebsiteShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="email" />
            <TextField source="ndd" />
            <TextField source="uuid" />

            <TextField source="type" />
            <TextField source="template" />

            <BooleanField source="calendly_active" />
            <UrlField source="calendly_link" />

            <TextField source="phone" />
            <TextField source="address" />
            <UrlField source="facebook" />
            <UrlField source="linkedin" />
            <UrlField source="youtube" />
            <UrlField source="instagram" />
            <UrlField source="twitter" />

            <TextField source="title" />
            <TextField source="desc" />
            <UrlField source='logo_url' />
            <OptionalImageField source='logo_url' />
            <UrlField source='favicon_url' />
            <OptionalImageField source='favicon_url' />
            <UrlField source='banner_url' />
            <OptionalImageField source='banner_url' />

            <TextField source="gmail_mail" />
            <TextField source="gmail_password" />
            <TextField source="stripe_private_key" />
            <TextField source="stripe_public_key" />

            <ReferenceField source="robots" reference="Media" >
                <TextField source="s3_key" />
            </ReferenceField>

            <ReferenceField source="matomo" reference="Media" >
                <TextField source="s3_key" />
            </ReferenceField>

            <ReferenceField source="sitemap" reference="Media" >
                <TextField source="s3_key" />
            </ReferenceField>

            <TextField source="google_analytics_meta" />
            <TextField source="google_ads_tracking_id" />

            <UrlField source='mail_banner_url' />
            <OptionalImageField source='mail_banner_url' />
            <TextField source="mail_signature" />


            <DateField source="createdAt" showTime={true} />
            <DateField source="updatedAt" showTime={true} />
        </SimpleShowLayout>
    </Show>
)

const CustomToolBar = props => <Toolbar {...props} ><SaveButton /></Toolbar>

export const WebsiteEdit = (props) => (
    <Edit {...props} undoable={false} >
        <SimpleForm redirect="show" toolbar={<CustomToolBar />}>
            <TextInput source="email" validate={required()} />
            <TextInput source="ndd" validate={required()} />

            <SelectInput source="type" choices={Array.from(["Vitrine", "Shop"], v => ({ id: v, name: `${v}` }))} />
            <TextInput source="template" />

            <BooleanInput source="calendly_active" />
            <TextInput source="calendly_link" />

            <TextInput source="phone" fullWidth />
            <TextInput source="address" fullWidth />
            <TextInput source="facebook" fullWidth />
            <TextInput source="linkedin" fullWidth />
            <TextInput source="youtube" fullWidth />
            <TextInput source="instagram" fullWidth />
            <TextInput source="twitter" fullWidth />

            <TextInput source="title" fullWidth />
            <TextInput source="desc" fullWidth />
            <GalleryInput source="logo" optionText="nameWithSize" />

            {/* <ReferenceInput source="logo" reference="Media" perPage={10000} >
                <AutocompleteInput optionText="nameWithSize" />
            </ReferenceInput> */}

            <GalleryInput source="favicon" />
            {/* <ReferenceInput source="favicon" reference="Media" perPage={10000} >
                <AutocompleteInput optionText="nameWithSize" />
            </ReferenceInput> */}

            <GalleryInput source="banner" optionText="nameWithSize" />
            {/* <ReferenceInput source="banner" reference="Media" perPage={10000} >
                <AutocompleteInput optionText="nameWithSize" />
            </ReferenceInput> */}

            <TextInput source="gmail_mail" fullWidth />
            <TextInput source="gmail_password" fullWidth />
            <TextInput source="stripe_private_key" fullWidth />
            <TextInput source="stripe_public_key" fullWidth />

            <GalleryInput source="robots" noImage />
            {/* <ReferenceInput source="robots" reference="Media" perPage={10000} >
                <AutocompleteInput optionText="s3_key" />
            </ReferenceInput> */}
            <GalleryInput source="matomo" noImage/>
            {/* <ReferenceInput source="matomo" reference="Media" perPage={10000} >
                <AutocompleteInput optionText="s3_key" />
            </ReferenceInput> */}
            <GalleryInput source="sitemap" noImage/>
            {/* <ReferenceInput source="sitemap" reference="Media" perPage={10000} >
                <AutocompleteInput optionText="s3_key" />
            </ReferenceInput> */}
            <TextInput source="google_analytics_meta" />
            <TextInput source="google_ads_tracking_id" />

            <GalleryInput source="mail_banner" optionText="nameWithSize" />
            {/* <ReferenceInput source="mail_banner" reference="Media" perPage={10000} >
                <AutocompleteInput optionText="s3_key" />
            </ReferenceInput> */}
            <TextInput source="mail_signature" />

        </SimpleForm>
    </Edit>
)

export const WebsiteCreate = (props) => (
    <Create {...props}>
        <SimpleForm redirect="show">
            <TextInput source="email" validate={required()} />
            <TextInput source="ndd" validate={required()} />
            <TextInput type='password' source="password" validate={required()} />
        </SimpleForm>
    </Create>
)
