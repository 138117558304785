
import { Component } from 'react'

import dataProvider from '../dataProvider'

class WebsitesSelector extends Component {

    constructor(props) {
        super(props)
        this.state = {
            selectedWebsite: this.props.multiple ? [] : null
        }
    }

    componentDidMount() {
        dataProvider.getList('Website', {
            pagination: { page: 1, perPage: 10000 },
            sort: { field: "createdAt", sort: "desc" },
            filter: this.props.shop ? { type: "shop" } : {}
        }).then(response => {
            const websites = [...response.data]
            if (this.props.hasOrphan) {
                websites.unshift({ ndd: "orphan", id: -1 })
            }
            if (this.props.hasAll) {
                websites.unshift({ ndd: "all", id: 0 })
            }
            this.setState({ websites, selectedWebsite: this.props.multiple ? [this.props.defaultWebsite] : this.props.defaultWebsite }, () => this.props.handleWebsiteSelect(this.state.websites, this.props.selectedWebsite))
        })
    }

    render() {
        return (
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {(this.state.websites || []).map((e, index) => (
                    <div key={index}
                        style={{ flex: '0 0 auto', margin: '10px' }}
                        onClick={() => {
                            if (this.props.multiple) {
                                const selectedWebsite = this.state.selectedWebsite.includes(e.id)
                                    ? this.state.selectedWebsite.filter(e2 => e2 !== e.id)
                                    : [...this.state.selectedWebsite, e.id]
                                this.setState({ selectedWebsite: selectedWebsite }, () => this.props.handleWebsiteSelect(this.state.websites, this.state.selectedWebsite))
                            } else {
                                this.setState({ selectedWebsite: e.id }, () => this.props.handleWebsiteSelect(this.state.websites, this.state.selectedWebsite))
                            }
                        }}
                    >
                        <div style={{
                            marginBottom: '5px', fontWeight: "bold", cursor: "pointer", color:
                                (this.props.multiple && this.state.selectedWebsite.includes(e.id))
                                    ||
                                    (!this.props.multiple && e.id === this.state.selectedWebsite)
                                    ?
                                    'grey' : 'black'
                        }}>
                            {e.ndd}
                        </div>
                    </div>
                ))
                }
            </div>
        )
    }
}

export default WebsitesSelector
