
import React from 'react'
import {
    List,
    Datagrid,
    Edit,
    Create,
    SimpleForm,
    DateField,
    TextField,
    ArrayField,
    SingleFieldList,
    EditButton,
    TextInput,
    DateInput,
    Responsive,
    SimpleList,
    SimpleShowLayout,
    ShowButton,
    Show,
    UrlField,
    SelectInput,
    Filter,
    useListContext,
    TopToolbar,
    CreateButton,
    ExportButton,
    Pagination,
    Button,
    ChipField,
    required,
    sanitizeListRestProps,
    SaveButton,
    DeleteButton,
    Toolbar,
    ArrayInput,
    SimpleFormIterator,
    NumberInput,
    AutocompleteInput,
    AutocompleteArrayInput,
    ReferenceInput,
    ReferenceArrayInput,
    SelectArrayInput,
    ImageField,
    ReferenceField
} from 'react-admin'
import { cloneElement, useMemo } from 'react'
import PropTypes from 'prop-types'
import BookIcon from '@material-ui/icons/AttachMoney'
import ShortUrlField from '../Customs/ShortUrlField'
import MultipleField from '../Customs/MultipleField'
import MultipleInput from '../Customs/MultipleInput'
import DeleteAllButton from '../Customs/DeleteAllButton'
import ImportButton from '../Customs/ImportButton'
import DuplicateButton from '../Customs/DuplicateButton'
import ApiSelectInput from '../Customs/ApiSelectInput'
import MoveFormIterator from '../Customs/MoveFormIterator'

const API_URL = process.env.REACT_APP_API_HOSTNAME

export const ExpenseIcon = BookIcon


const Expense_Filter = (props) => (
    <Filter {...props}>
        <TextInput source="title" options={{ maxLength: 1024 }} />
        <TextInput source="description" options={{ maxLength: 1024 }} />
        <TextInput source="type" options={{ maxLength: 1024 }} />
        <TextInput source="recurring_type" options={{ maxLength: 1024 }} />
        <ReferenceInput source="website" reference="Website" perPage={10000} >
            <AutocompleteInput optionText="ndd" />
        </ReferenceInput>
    </Filter>
)

const ListActions_Store = {}

const ListActions = (props) => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props
    let {
        currentSort,
        resource,
        displayedFilters,
        filterValues,
        hasCreate,
        basePath,
        selectedIds,
        showFilter,
        total,
    } = useListContext()
    ListActions_Store.filterValues = filterValues
    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
            <CreateButton basePath={basePath} />
        </TopToolbar>
    )
}

export const ExpenseList = (props) => (
    <List {...props} filters={<Expense_Filter />} actions={<ListActions />} perPage={10}>
        <Responsive
            small={
                <SimpleList linkType="show"
                    primaryText={record => record ? record.title : ''}
                />
            }
            medium={
                <Datagrid>
                    <TextField source="id" sortable={true} />
                    <TextField source="title" sortable={true} />
                    <TextField source="description" sortable={false} />
                    <TextField source="amount" sortable={false} />
                    <DateField source="date" sortable={true} />
                    <TextField source="type" sortable={false} />
                    <TextField source="recurring_type" sortable={false} />
                    <DateField source="end" sortable={false} />
                    <ReferenceField source="website" reference="Website" >
                        <TextField source="ndd" />
                    </ReferenceField>
                    <DateField source="updatedAt" showTime={true} sortable={true} />
                    <EditButton />
                    <ShowButton />
                    <DuplicateButton tranformFields={[
                        {
                            f: 'date',
                            t: (date) => date.split('T')[0]
                        },
                    ]} />
                </Datagrid>
            }
        />
    </List>
)

export const ExpenseShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="title" />
            <TextField source="description" />
            <TextField source="amount" />
            <DateField source="date" />
            <TextField source="type" />
            <TextField source="recurring_type" />
            <DateField source="end" />
            <ReferenceField source="website" reference="Website" >
                <TextField source="ndd" />
            </ReferenceField>
            <DateField source="createdAt" showTime={true} />
            <DateField source="updatedAt" showTime={true} />
        </SimpleShowLayout>
    </Show>
)

const CustomToolBar = props => <Toolbar {...props} ><SaveButton /></Toolbar>

export const ExpenseEdit = (props) => (
    <Edit {...props} undoable={false} toolbar={<CustomToolBar />} >
        <SimpleForm redirect="show">
            <TextInput source="title" validate={required()} style={{ width: "800px" }} />
            <TextInput source="description" style={{ width: "800px" }} />
            <NumberInput source="amount" validate={required()} />
            <DateInput source="date" validate={required()} />
            <SelectInput source="type" defaultValue={"single"} choices={Array.from(["single", "recurrent"], v => ({ id: v, name: `${v}` }))} />
            <SelectInput source="recurring_type" choices={Array.from(["monthly", "yearly"], v => ({ id: v, name: `${v}` }))} />
            <DateInput source="end" />
            <ReferenceInput source="website" reference="Website" validate={required()} perPage={10000} >
                <AutocompleteInput optionText="ndd" />
            </ReferenceInput>
        </SimpleForm>
    </Edit>
)

export const ExpenseCreate = (props) => (
    <Create {...props} undoable={false} toolbar={<CustomToolBar />} >
        <SimpleForm redirect="show">
            <TextInput source="title" validate={required()} style={{ width: "800px" }} />
            <TextInput source="description" style={{ width: "800px" }} />
            <NumberInput source="amount" validate={required()} />
            <DateInput source="date" validate={required()} />
            <SelectInput source="type" defaultValue={"single"} choices={Array.from(["single", "recurrent"], v => ({ id: v, name: `${v}` }))} />
            <SelectInput source="recurring_type" choices={Array.from(["monthly", "yearly"], v => ({ id: v, name: `${v}` }))} />
            <DateInput source="end" />
            <ReferenceInput source="website" reference="Website" validate={required()} perPage={10000} >
                <AutocompleteInput optionText="ndd" />
            </ReferenceInput>
        </SimpleForm>
    </Create>
)
