
import React from 'react'
import {
    List,
    Datagrid,
    Edit,
    Create,
    SimpleForm,
    DateField,
    TextField,
    ArrayField,
    SingleFieldList,
    EditButton,
    TextInput,
    DateInput,
    Responsive,
    SimpleList,
    SimpleShowLayout,
    ShowButton,
    Show,
    UrlField,
    SelectInput,
    Filter,
    useListContext,
    TopToolbar,
    CreateButton,
    ExportButton,
    Pagination,
    Button,
    ChipField,
    required,
    sanitizeListRestProps,
    SaveButton,
    DeleteButton,
    Toolbar,
    NumberInput,
    FileInput,
    FileField,
    translate,
    ImageField,
    ReferenceField,
    ReferenceInput,
    RichTextField,
    BooleanField,
    BooleanInput,
    AutocompleteInput,
    SearchInput,
} from 'react-admin'
import { cloneElement, useMemo } from 'react'
import PropTypes from 'prop-types'
import BookIcon from '@material-ui/icons/BookOutlined'
import RichTextInput from 'ra-input-rich-text';
import OptionalImageField from '../Customs/OptionalImageField'
import dataProvider from '../dataProvider'

const API_URL = process.env.REACT_APP_API_HOSTNAME

export const PromptIcon = BookIcon

const Prompt_Filter = (props) => (
    <Filter {...props}>
        <SearchInput source="search" alwaysOn />
        <TextInput source="fullname" options={{ maxLength: 1024 }} />
    </Filter>
)

const ListActions_Store = {}

const ListActions = (props) => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props
    let {
        currentSort,
        resource,
        displayedFilters,
        filterValues,
        hasCreate,
        basePath,
        selectedIds,
        showFilter,
        total,
    } = useListContext()
    ListActions_Store.filterValues = filterValues
    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
            <CreateButton basePath={basePath} />
        </TopToolbar>
    )
}

export const PromptList = (props) => (
    <List {...props} filters={<Prompt_Filter />} actions={<ListActions />} perPage={50}>
        <Responsive
            small={
                <SimpleList linkType="show"
                    primaryText={record => record ? record.fullname : ''}
                />
            }
            medium={
                <Datagrid>
                    <TextField source="fullname" sortable={false} />
                    <TextField source="prompt" sortable={false} />
                    <TextField source="max_tokens" sortable={false} />
                    <TextField source="temperature" sortable={false} />
                    <DateField source="updatedAt" showTime={true} sortable={true} />
                    <EditButton />
                    <ShowButton />
                </Datagrid>
            }
        />
    </List>
)


export const PromptShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="fullname" />
            <TextField source="prompt" />
            <TextField source="max_tokens" />
            <TextField source="temperature" />
            <DateField source="createdAt" showTime={true} />
            <DateField source="updatedAt" showTime={true} />
        </SimpleShowLayout>
    </Show>
)

const CustomToolBar = props => <Toolbar {...props} ><SaveButton /></Toolbar>

export const PromptEdit = (props) => (
    <Edit {...props} undoable={false} toolbar={<CustomToolBar />} >
        <SimpleForm redirect="show">
            <TextInput source="prompt" validate={required()} multiline rows={10} style={{ width: "800px" }} />
            <TextInput source="max_tokens" validate={required()} type="number" />
            <TextInput source="temperature" type="number" />
        </SimpleForm>
    </Edit>
)

export const PromptCreate = (props) => (
    <Create {...props}>
        <SimpleForm redirect="show">
            <TextInput source="name" validate={required()} />
            <TextInput source="prompt" validate={required()} multiline rows={10} style={{ width: "800px" }} />
            <TextInput source="max_tokens" validate={required()} type="number" />
            <TextInput source="temperature" type="number" />
        </SimpleForm>
    </Create>
)

