
import { Component, createRef } from 'react'
import {
    fetchUtils, useNotify,
} from 'react-admin'
import { stringify } from 'query-string'
import TextField from '@material-ui/core/TextField'

import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import MenuItem from '@material-ui/core/MenuItem'
import CircularProgress from '@material-ui/core/CircularProgress'

import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';


import dataProvider from '../dataProvider'
import authProvider from '../authProvider'
import WebsitesSelector from '../components/WebsitesSelector'
import TinyTextField from '../components/TinyTextField'

export const SaveButton = (props) => {
    const notify = useNotify();

    return (
        <Button
            variant='outlined'
            onClick={() => props.save().then(() => {
                notify("Update !");
            })}>
            SAVE
        </Button>
    );
};

class ReviewProductsByOpenai extends Component {

    constructor(props) {
        super(props)
        this.state = {
            titlePrompt: "",
            loadPrompt_index: 0,
            loadRes_index: 0,
            availableTags: [],
            availableCategories: [],
            tags: [],
            categories: []
        }

        this.refInfoEditor = createRef()
        this.refDetailsEditor = createRef()
    }

    componentDidMount() {
        authProvider.checkAuth().then(() => {
            this.getProducts()
        }).catch(() => {
            authProvider.logout().then(() => window.location = '/#/login')
        })
        setTimeout(() => {
            this.configTagsStateFromLocalStorage()
        }, 200)
    }

    configTagsStateFromLocalStorage() {
        const a = localStorage.getItem('products-tags')
        if (a) {
            this.setState({ availableTags: Array.from(new Set([...this.state.tags, ...JSON.parse(a)])) }, () => {
                const b = localStorage.getItem('products-categories')
                if (b) {
                    this.setState({ availableCategories: Array.from(new Set([...this.state.categories, ...JSON.parse(b)])) })
                }
            })
        }
    }

    getProducts() {
        const { website, offset } = this.getWebsiteFromWebsiteUrl()
        if (website) {
            dataProvider.getList('Product', {
                pagination: { page: 1, perPage: 10000 },
                sort: { field: "createdAt", order: "desc" },
                filter: { website }
            }).then(response => {
                const availableCategories = response.data.map(e => e.categories).reduce((acc, v) => Array.from(new Set([...acc, ...v])), [])
                const availableTags = response.data.map(e => e.tags).reduce((acc, v) => Array.from(new Set([...acc, ...v])), [])
                this.setState({
                    product: response.data[offset], products: response.data, total: response.total,
                    availableCategories,
                    availableTags
                }, () => {
                    this.configTagsStateFromLocalStorage()
                })
            })
        }
    }

    redirectWebsiteUrl(selectedWebsite) {
        const pathname = document.location.href.split(document.location.hostname)[1]
        const endpoint = pathname.split('#')[0]
        const anchor = pathname.split('#')[1]
        document.location = endpoint + '#' + anchor + '?website=' + selectedWebsite + '&offset=0'
        this.getProducts()
        // setTimeout(() => window.location.reload(), 100)
    }

    getWebsiteFromWebsiteUrl() {
        const search = window.location.href.split('#')[1].split('?')[1]
        const website = new URLSearchParams(search).get("website")
        const offset = parseInt(new URLSearchParams(search).get("offset"))
        return { website, offset }
    }

    getOverrideValueProduct(valueName) {
        if (this.state[valueName] === undefined || this.state[valueName] === null)
            return this.state.product[valueName]
        return this.state[valueName]
    }

    submit() {
        return dataProvider.update('Product', {
            data: {
                ...this.state.product,
                title: this.getOverrideValueProduct("title"),
                description: this.getOverrideValueProduct("description"),
                details: this.getOverrideValueProduct("details"),
                info: this.getOverrideValueProduct("info"),
                categories: this.state.product.categories,
                tags: this.state.product.tags,
            }
        })
    }

    generate(valueName, label) {
        this.setState({
            [`loading${label}`]: true
        }, () => {
            dataProvider.customs.openaiChat({
                prompt: this.state[`${valueName}Prompt`] + ' ' + this.getOverrideValueProduct(valueName)
            }).then((json) => {
                this.setState({ [`${valueName}Response`]: json.data, [`loading${label}`]: false })
            })
        })
    }

    next() {
        const { website, offset } = this.getWebsiteFromWebsiteUrl()
        const pathname = document.location.href.split(document.location.hostname)[1]
        const endpoint = pathname.split('#')[0]
        const anchor = pathname.split('#')[1].split('?')[0]
        document.location = endpoint + '#' + anchor + '?website=' + website + '&offset=' + (offset + 1)
        this.setState({ product: this.state.products[offset + 1], title: null, description: null, details: null, info: null }, () => {
            this.refDetailsEditor.current.change(this.state.product.details)
            this.refInfoEditor.current.change(this.state.product.info)
        })
        // setTimeout(() => window.location.reload(), 100)
    }

    prev() {
        const { website, offset } = this.getWebsiteFromWebsiteUrl()
        const pathname = document.location.href.split(document.location.hostname)[1]
        const endpoint = pathname.split('#')[0]
        const anchor = pathname.split('#')[1].split('?')[0]
        document.location = endpoint + '#' + anchor + '?website=' + website + '&offset=' + (offset - 1)
        this.setState({ product: this.state.products[offset - 1], title: null, description: null, details: null, info: null }, () => {
            this.refDetailsEditor.current.change(this.state.product.details)
            this.refInfoEditor.current.change(this.state.product.info)
        })
        // setTimeout(() => window.location.reload(), 100)
    }

    savePrompt() {
        if (!this.state.savePrompt_title) {
            this.setState({ savePrompt_title_error: true })
        } else {
            this.setState({ savePrompt_title_error: false })
        }
        if (!this.state.savePrompt_content) {
            this.setState({ savePrompt_content_error: true })
        } else {
            this.setState({ savePrompt_content_error: false })
        }
        if (this.state.savePrompt_title && this.state.savePrompt_content) {
            const rawList = localStorage.getItem('savedPromptTitles')
            const list = rawList ? JSON.parse(rawList) : []
            list.push(this.state.savePrompt_title)
            localStorage.setItem('savedPromptTitles', JSON.stringify(Array.from(new Set(list))))
            localStorage.setItem('savePrompt_' + this.state.savePrompt_title, this.state.savePrompt_content)
            this.setState({ dialogSavePromptOpen: false })
        }
    }

    saveRes() {
        if (!this.state.saveRes_title) {
            this.setState({ saveRes_title_error: true })
        } else {
            this.setState({ saveRes_title_error: false })
        }
        if (!this.state.saveRes_content) {
            this.setState({ saveRes_content_error: true })
        } else {
            this.setState({ saveRes_content_error: false })
        }
        if (this.state.saveRes_title && this.state.saveRes_content) {
            const rawList = localStorage.getItem('savedResTitles')
            const list = rawList ? JSON.parse(rawList) : []
            list.push(this.state.saveRes_title)
            localStorage.setItem('savedResTitles', JSON.stringify(Array.from(new Set(list))))
            localStorage.setItem('saveRes_' + this.state.saveRes_title, this.state.saveRes_content)
            this.setState({ dialogSaveResOpen: false })
        }
    }

    renderDialogSavePrompt() {
        return (
            <Dialog open={this.state.dialogSavePromptOpen} onClose={() => this.setState({ dialogSavePromptOpen: false })} maxWidth="lg" fullWidth>
                <DialogTitle>Save Prompt</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Prompt Title"
                        variant="outlined"
                        value={this.state.savePrompt_title}
                        onChange={(event) => this.setState({ savePrompt_title: event.target.value })}
                        fullWidth
                        margin="normal"
                        error={this.state.savePrompt_title_error}
                    />
                    <TextField
                        label="Prompt"
                        variant="outlined"
                        value={this.state.savePrompt_content}
                        onChange={(event) => this.setState({ savePrompt_content: event.target.value })}
                        fullWidth
                        multiline
                        rows={6}
                        margin="normal"
                        error={this.state.savePrompt_content_error}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        localStorage.setItem('savePromptLast' + this.state.loadRes_field, this.state.savePrompt_content)
                        this.savePrompt()
                    }} color="primary">
                        Submit
                    </Button>
                    <Button onClick={() => this.setState({ dialogSavePromptOpen: false })} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    renderDialogLoadPrompt() {
        const rawList = localStorage.getItem('savedPromptTitles')
        const list = rawList ? JSON.parse(rawList) : []
        const content = localStorage.getItem('savePrompt_' + list[this.state.loadPrompt_index])

        return (
            <Dialog open={this.state.dialogLoadPromptOpen} onClose={() => this.setState({ dialogLoadPromptOpen: false })} maxWidth="lg" fullWidth>
                <DialogTitle>Load Prompt</DialogTitle>
                <DialogContent>
                    <Select
                        label="Prompt Title"
                        variant="outlined"
                        value={this.state.loadPrompt_index}
                        fullWidth
                        margin="normal"
                        onChange={(event) => {
                            this.setState({ loadPrompt_index: event.target.value })
                        }}
                    >
                        {list.map((option, index) => (
                            <MenuItem key={index} value={index}>
                                {list[index]}
                            </MenuItem>
                        ))}
                    </Select>
                    <TextField
                        label="Prompt"
                        variant="outlined"
                        value={content || '-'}
                        fullWidth
                        multiline
                        rows={6}
                        margin="normal"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        localStorage.setItem('savePromptLast' + this.state.loadPrompt_field, content)
                        this.setState({ dialogLoadPromptOpen: false, [this.state.loadPrompt_field]: content })
                    }} color="primary">
                        Submit
                    </Button>
                    <Button onClick={() => this.setState({ dialogLoadPromptOpen: false })} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    renderDialogSaveRes() {
        return (
            <Dialog open={this.state.dialogSaveResOpen} onClose={() => this.setState({ dialogSaveResOpen: false })} maxWidth="lg" fullWidth>
                <DialogTitle>Save Response</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Res Title"
                        variant="outlined"
                        value={this.state.saveRes_title}
                        onChange={(event) => this.setState({ saveRes_title: event.target.value })}
                        fullWidth
                        margin="normal"
                        error={this.state.saveRes_title_error}
                    />
                    <TextField
                        label="Res"
                        variant="outlined"
                        value={this.state.saveRes_content}
                        onChange={(event) => this.setState({ saveRes_content: event.target.value })}
                        fullWidth
                        multiline
                        rows={6}
                        margin="normal"
                        error={this.state.saveRes_content_error}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        localStorage.setItem('saveResponseLast' + this.state.loadRes_field, this.state.saveRes_content)
                        this.saveRes()
                    }} color="primary">
                        Submit
                    </Button>
                    <Button onClick={() => this.setState({ dialogSaveResOpen: false })} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    renderDialogLoadRes() {
        const rawList = localStorage.getItem('savedResTitles')
        const list = rawList ? JSON.parse(rawList) : []
        const content = localStorage.getItem('saveRes_' + list[this.state.loadRes_index])

        return (
            <Dialog open={this.state.dialogLoadResOpen} onClose={() => this.setState({ dialogLoadResOpen: false })} maxWidth="lg" fullWidth>
                <DialogTitle>Load Res</DialogTitle>
                <DialogContent>
                    <Select
                        label="Res Title"
                        variant="outlined"
                        value={this.state.loadRes_index}
                        fullWidth
                        margin="normal"
                        onChange={(event) => {
                            this.setState({ loadRes_index: event.target.value })
                        }}
                    >
                        {list.map((option, index) => (
                            <MenuItem key={index} value={index}>
                                {list[index]}
                            </MenuItem>
                        ))}
                    </Select>
                    <TextField
                        label="Res"
                        variant="outlined"
                        value={content || '-'}
                        fullWidth
                        multiline
                        rows={6}
                        margin="normal"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        localStorage.setItem('saveResponseLast' + this.state.loadRes_field, content)
                        this.setState({ dialogLoadResOpen: false, [this.state.loadRes_field]: content }, () => {
                            const mapping = {
                                "details": this.refDetailsEditor,
                                "info": this.refInfoEditor,
                            }
                            if (mapping[this.state.loadRes_field])
                                mapping[this.state.loadRes_field].current.change(content)
                        })
                    }} color="primary">
                        Submit
                    </Button>
                    <Button onClick={() => this.setState({ dialogLoadResOpen: false })} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    renderProductNavBar() {
        const { offset } = this.getWebsiteFromWebsiteUrl()

        return (
            <Card style={{ margin: "10px" }}>
                <CardContent>
                    <div style={{ display: "flex" }}>
                        <SaveButton
                            save={() => this.submit()}
                        />
                        <div style={{ padding: "10px" }}>{offset + 1} / {this.state.total}</div>
                        {
                            offset > 0 && (
                                <Button
                                    variant='outlined'
                                    onClick={() => this.prev()}>
                                    PREV
                                </Button>
                            )
                        }
                        {
                            offset + 1 < this.state.total && (
                                <Button
                                    variant='outlined'
                                    onClick={() => this.next()}>
                                    NEXT
                                </Button>
                            )
                        }
                    </div>
                </CardContent>
            </Card>
        )
    }

    renderProductPreview() {
        const product = this.state.product

        return (
            <Card style={{ margin: "10px" }}>
                <CardContent>
                    <div style={{ display: "flex" }}>
                        <div>
                            <div style={{ width: "150px", margin: "auto" }}>
                                <img style={{ width: "150px" }} src={product.preview_media_url} />
                            </div>
                        </div>
                        <div style={{ marginLeft: "40px" }}>
                            <div style={{ display: "flex" }}>
                                <div style={{ paddingTop: "10px", marginRight: "10px" }}>{product.variant1_name}</div>
                                {(product.variant1_choices || []).map(e => (<div style={{ paddingTop: "10px", marginRight: "10px" }}>{e.value}</div>))}
                                {(product.variant1_choices_images_url || []).map(e => (<div><img style={{ width: "40px" }} src={e.value} /></div>))}
                            </div>
                            <div style={{ display: "flex" }}>
                                <div style={{ paddingTop: "10px", marginRight: "10px" }}>{product.variant2_name}</div>
                                {(product.variant2_choices || []).map(e => (<div style={{ paddingTop: "10px", marginRight: "10px" }}>{e.value}</div>))}
                                {(product.variant2_choices_images_url || []).map(e => (<div><img style={{ width: "40px" }} src={e.value} /></div>))}
                            </div>
                            <div style={{ display: "flex" }}>
                                <div style={{ paddingTop: "10px", marginRight: "10px" }}>{product.variant3_name}</div>
                                {(product.variant3_choices || []).map(e => (<div style={{ paddingTop: "10px", marginRight: "10px" }}>{e.value}</div>))}
                                {(product.variant3_choices_images_url || []).map(e => (<div><img style={{ width: "40px" }} src={e.value} /></div>))}
                            </div>
                        </div>
                        <div style={{ marginLeft: "40px" }}>
                            <div>
                                <div>Product ID: {product.id}</div>
                                <div>Product Slug: /{product.slug_url}</div>
                            </div>
                        </div>
                    </div>
                </CardContent>
            </Card >
        )
    }

    renderProductTags() {
        const product = this.state.product

        return (
            <Card style={{ margin: "10px" }}>
                <CardContent>
                    <>
                        <div style={{ display: 'flex' }}>
                            <div style={{ flex: 7 }}>
                                <TextField
                                    label="Category / Tag"
                                    variant="outlined"
                                    value={this.state.tag}
                                    onChange={(event) => this.setState({ tag: event.target.value })}
                                    fullWidth
                                    margin="normal"
                                />
                            </div>
                            <div style={{ flex: 1, marginLeft: "10px", paddingTop: "16px" }}>
                                <Button
                                    variant="outlined"
                                    fullWidth
                                    onClick={() => this.setState({ availableCategories: Array.from(new Set([...this.state.availableCategories, this.state.tag])) }, () => localStorage.setItem('products-categories', JSON.stringify(this.state.availableCategories)))}
                                >
                                    ADD CAT
                                </Button>
                            </div>
                            <div style={{ flex: 1, marginLeft: "10px", paddingTop: "16px" }}>
                                <Button
                                    variant="outlined"
                                    fullWidth
                                    onClick={() => this.setState({ availableTags: Array.from(new Set([...this.state.availableTags, this.state.tag])) }, () => localStorage.setItem('products-tags', JSON.stringify(this.state.availableTags)))}
                                >
                                    ADD TAG
                                </Button>
                            </div>
                        </div>
                        <div style={{ display: 'flex' }}>
                            <div style={{ flex: 1, paddingTop: "20px" }}>
                                Categories:
                            </div>
                            <div style={{ flex: 9, display: 'flex', flexWrap: 'wrap' }}>
                                {
                                    this.state.availableCategories.map(e => (
                                        <div style={{ marginLeft: "10px", paddingTop: "16px" }}>
                                            <Button
                                                variant={product.categories.includes(e) ? "outlined" : "text"}
                                                fullWidth
                                                onClick={() => {
                                                    !product.categories.includes(e) ? product.categories.push(e) : product.categories = product.categories.filter(e2 => e !== e2)
                                                    this.setState({ product: { ...this.state.product } })
                                                }}
                                            >
                                                {e}
                                            </Button>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                        <div style={{ display: 'flex' }}>
                            <div style={{ flex: 1, paddingTop: "20px" }}>
                                Tags:
                            </div>
                            <div style={{ flex: 9, display: 'flex', flexWrap: 'wrap' }}>
                                {
                                    this.state.availableTags.map(e => (
                                        <div style={{ marginLeft: "10px", paddingTop: "16px" }}>
                                            <Button
                                                variant={product.tags.includes(e) ? "outlined" : "text"}
                                                fullWidth
                                                onClick={() => {
                                                    !product.tags.includes(e) ? product.tags.push(e) : product.tags = product.tags.filter(e2 => e !== e2)
                                                    this.setState({ product: { ...this.state.product } })
                                                }}
                                            >
                                                {e}
                                            </Button>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>

                    </>
                </CardContent>
            </Card>
        )
    }

    renderProductResponseButton(valueName, labelName) {
        const product = this.state.product
        return (
            <>
                <div>
                    <Button
                        variant="outlined"
                        fullWidth
                        onClick={() => this.setState({ dialogSaveResOpen: true, saveRes_content: this.state[valueName] || product[valueName] })}
                    >
                        Save Response
                    </Button>
                </div>
                <div style={{ marginTop: "10px" }}>
                    <Button
                        variant="outlined"
                        fullWidth
                        onClick={() => this.setState({ dialogLoadResOpen: true, loadRes_field: valueName })}
                    >
                        Load Response
                    </Button>
                </div>
                <div style={{ marginTop: "10px" }}>
                    <Button
                        variant="outlined"
                        fullWidth
                        onClick={() => this.setState({ [valueName]: localStorage.getItem("saveResponseLast" + valueName) }, () => {
                            const mapping = {
                                "details": this.refDetailsEditor,
                                "info": this.refInfoEditor,
                            }
                            if (mapping[valueName] && this.state[valueName])
                                mapping[valueName].current.change(this.state[valueName])

                        })}
                    >
                        Last Response
                    </Button>
                </div>
            </>
        )
    }

    renderProductPromptButton(valueName, labelName) {
        return (
            <>
                {
                    this.state[`loading${labelName}`] ?
                        <div><CircularProgress /></div> :
                        <div>
                            <Button
                                variant="outlined"
                                fullWidth
                                onClick={() => this.generate(valueName, labelName)}
                            >
                                Generate
                            </Button>
                        </div>
                }
                <div style={{ marginTop: "10px" }}>
                    <Button
                        variant="outlined"
                        fullWidth
                        onClick={() => this.setState({ dialogSavePromptOpen: true, savePrompt_content: this.state[`${valueName}Prompt`] })}
                    >
                        Save Prompt
                    </Button>
                </div>
                <div style={{ marginTop: "10px" }}>
                    <Button
                        variant="outlined"
                        fullWidth
                        onClick={() => this.setState({ dialogLoadPromptOpen: true, loadPrompt_field: `${valueName}Prompt` })}
                    >
                        Load Prompt
                    </Button>
                </div>
                <div style={{ marginTop: "10px" }}>
                    <Button
                        variant="outlined"
                        fullWidth
                        onClick={() => this.setState({ [`${valueName}Prompt`]: localStorage.getItem("savePromptLast" + valueName) })}
                    >
                        Last Prompt
                    </Button>
                </div>
            </>
        )
    }

    renderProductPrompt(valueName, labelName) {
        return (
            <>
                <Button onClick={() => this.setState({ [`see${labelName}Prompt`]: !this.state[`see${labelName}Prompt`] })}>{this.state[`see${labelName}Prompt`] ? 'Hide' : 'See'} Prompt</Button>
                {
                    this.state[`see${labelName}Prompt`] && (
                        <>
                            <div style={{ display: 'flex' }}>
                                <div style={{ flex: 8 }}>
                                    <TextField
                                        label="Prompt"
                                        variant="outlined"
                                        value={this.state[`${valueName}Prompt`]}
                                        onChange={(event) => this.setState({ [`${valueName}Prompt`]: event.target.value })}
                                        fullWidth
                                        multiline
                                        rows={6}
                                        margin="normal"
                                    />
                                </div>
                                <div style={{ flex: 1, marginLeft: "10px", paddingTop: "16px" }}>
                                    {this.renderProductPromptButton(valueName, labelName)}
                                </div>
                            </div>
                            {
                                this.state[`${valueName}Response`] && (
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ flex: 8 }}>
                                            <TextField
                                                label="Response"
                                                variant="outlined"
                                                value={this.state[`${valueName}Response`]}
                                                fullWidth
                                                margin="normal"
                                            />
                                        </div>
                                        <div style={{ marginLeft: "10px", paddingTop: "16px" }}>
                                            <Button
                                                variant="outlined"
                                                onClick={() => this.setState({ title: this.state[`${valueName}Response`], [`${valueName}Response`]: null })}
                                            >
                                                Valider
                                            </Button>
                                        </div>
                                    </div>
                                )
                            }
                        </>
                    )
                }
            </>
        )
    }

    renderProduct() {
        const product = this.state.product
        return (
            <div>
                {this.renderProductNavBar()}
                {this.renderProductPreview()}
                {this.renderProductTags()}
                <Card style={{ margin: "10px" }}>
                    <CardContent>
                        <Button onClick={() => this.setState({ hideTitle: !this.state.hideTitle })}>{this.state.hideTitle ? 'See' : 'Hide'} Title</Button>
                        {!this.state.hideTitle && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ flex: 8 }}>
                                        <TextField
                                            label="Title"
                                            variant="outlined"
                                            defaultValue={product.title}
                                            value={this.state.title || product.title}
                                            onChange={(event) => this.setState({ title: event.target.value })}
                                            fullWidth
                                            margin="normal"
                                        />
                                    </div>
                                    <div style={{ flex: 1, marginLeft: "10px", paddingTop: "16px" }}>
                                        {this.renderProductResponseButton("title", "Title")}
                                    </div>

                                </div>
                                {this.renderProductPrompt("title", "Title")}
                            </>
                        )}
                    </CardContent>
                </Card>
                <Card style={{ margin: "10px" }}>
                    <CardContent>
                        <Button onClick={() => this.setState({ hideDescription: !this.state.hideDescription })}>{this.state.hideDescription ? 'See' : 'Hide'} Description</Button>
                        {!this.state.hideDescription && (
                            <>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ flex: 8 }}>
                                        <TextField
                                            label="Description"
                                            variant="outlined"
                                            defaultValue={product.description}
                                            value={this.state.description || product.description}
                                            onChange={(event) => this.setState({ description: event.target.value })}
                                            fullWidth
                                            multiline
                                            rows={6}
                                            margin="normal"
                                        />
                                    </div>
                                    <div style={{ flex: 1, marginLeft: "10px", paddingTop: "16px" }}>
                                        {this.renderProductResponseButton("description", "Description")}
                                    </div>

                                </div>
                                {this.renderProductPrompt("description", "Description")}
                            </>
                        )}
                    </CardContent>
                </Card>
                <Card style={{ margin: "10px" }}>
                    <CardContent>
                        <Button onClick={() => this.setState({ hideDetails: !this.state.hideDetails })}>{this.state.hideDetails ? 'See' : 'Hide'} Details</Button>
                        {!this.state.hideDetails && (
                            <div>
                                <Button onClick={() => this.setState({ modeHTMLDetails: !this.state.modeHTMLDetails })}>{this.state.modeHTMLDetails ? 'HTML' : 'Rich'}</Button>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ flex: 8 }}>
                                        {
                                            this.state.modeHTMLDetails ? (
                                                <TextField
                                                    label="Detail"
                                                    variant="outlined"
                                                    value={this.state.details || product.details}
                                                    onChange={(event) => this.setState({ details: event.target.value })}
                                                    fullWidth
                                                    multiline
                                                    rows={12}
                                                    margin="normal"
                                                />
                                            ) : (
                                                <TinyTextField
                                                    ref={this.refDetailsEditor}
                                                    label="Details"
                                                    defaultValue={product.details}
                                                    onChange={(content) => this.setState({ details: content })}
                                                    margin="normal"
                                                />
                                            )
                                        }
                                    </div>
                                    <div style={{ flex: 1, marginLeft: "10px", paddingTop: "16px" }}>
                                        {this.renderProductResponseButton("details", "Details")}
                                    </div>
                                </div>
                                {this.renderProductPrompt("details", "Details")}
                            </div>
                        )}
                    </CardContent>
                </Card>
                <Card style={{ margin: "10px" }}>
                    <CardContent>
                        <Button onClick={() => this.setState({ hideInfo: !this.state.hideInfo })}>{this.state.hideInfo ? 'See' : 'Hide'} Info</Button>
                        {!this.state.hideInfo && (
                            <div>
                                <Button onClick={() => this.setState({ modeHTMLInfo: !this.state.modeHTMLInfo })}>{this.state.modeHTMLInfo ? 'HTML' : 'Rich'}</Button>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ flex: 8 }}>
                                        {
                                            this.state.modeHTMLInfo ? (
                                                <TextField
                                                    label="Info"
                                                    variant="outlined"
                                                    value={this.state.info || product.info}
                                                    onChange={(event) => this.setState({ info: event.target.value })}
                                                    fullWidth
                                                    multiline
                                                    rows={12}
                                                    margin="normal"
                                                />
                                            ) : (
                                                <TinyTextField
                                                    ref={this.refInfoEditor}
                                                    label="Info"
                                                    defaultValue={product.info}
                                                    onChange={(content) => this.setState({ info: content })}
                                                    margin="normal"
                                                />
                                            )
                                        }

                                    </div>
                                    <div style={{ flex: 1, marginLeft: "10px", paddingTop: "16px" }}>
                                        {this.renderProductResponseButton("info", "Info")}
                                    </div>
                                </div>
                                {this.renderProductPrompt("info", "Info")}
                            </div>
                        )}
                    </CardContent>
                </Card>
            </div>
        )
    }

    render() {

        const { website } = this.getWebsiteFromWebsiteUrl()

        return (
            <div>
                {
                    !website && (
                        <WebsitesSelector
                            shop
                            handleWebsiteSelect={(websites, selectedWebsite) => {
                                if (selectedWebsite)
                                    this.redirectWebsiteUrl(selectedWebsite)
                            }}
                        />
                    )
                }
                {
                    website && this.state.total === 0 &&
                    <div>NO PRODUCTS</div>
                }
                {
                    this.state.product && website &&
                    <div>
                        {this.renderProduct()}
                    </div>
                }
                {this.renderDialogSavePrompt()}
                {this.renderDialogLoadPrompt()}
                {this.renderDialogSaveRes()}
                {this.renderDialogLoadRes()}
            </div>
        )
    }
}

export default ReviewProductsByOpenai
