
import { Component } from 'react'
import {
    fetchUtils,
} from 'react-admin'
import { stringify } from 'query-string'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import MenuItem from '@material-ui/core/MenuItem'
import Chart from "react-apexcharts";
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import dataProvider from '../dataProvider'
import authProvider from '../authProvider'

const { fetchJson } = fetchUtils

const API_URL = process.env.REACT_APP_API_HOSTNAME

class SearchUnsplashImages extends Component {

    constructor(props) {
        super(props)
        this.state = {
            search: '',
            nocache: 0
        }
    }

    componentDidMount() {
        authProvider.checkAuth().then(() => {
        }).catch(() => {
            authProvider.logout().then(() => window.location = '/#/login')
        })
    }

    callUnsplash() {
        this.setState({ loading: true, data: [] }, () => {
            dataProvider.customs.unsplashSearch(this.state.search, this.state.nocache).then((res) => {
                this.setState({ data: res.images, loading: false })
            })
        })
    }

    callPixabay() {
        this.setState({ loading: true, data: [] }, () => {
            dataProvider.customs.pixabaySearch(this.state.search, this.state.nocache).then((res) => {
                this.setState({ data: res.images, loading: false })
            })
        })
    }

    upload(elem) {
        this.setState({ loading: true }, () => {
            dataProvider.customs.imagesSearchUpload({ ...elem, name: this.state.search }).then((res) => {
                elem.uploaded = true
                this.setState({ data: [...this.state.data], loading: false })
            })
        })
    }

    renderData() {
        return (
            <TableContainer component={Paper}>
                <Table styles={{ minWidth: 650, }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Url</TableCell>
                            <TableCell>Size</TableCell>
                            <TableCell>Image</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(this.state.data || []).map((row) => (
                            <TableRow key={row.image}>
                                <TableCell component="th" scope="row">
                                    {row.image.substring(0, 64)}
                                </TableCell>
                                <TableCell>{row.width} x {row.height}</TableCell>
                                <TableCell><img style={{ width: "600px" }} src={row.image} /></TableCell>
                                <TableCell>
                                    {
                                        row.uploaded ?
                                            <span>{"Uploaded"}</span>
                                            :
                                            <Button
                                                disabled={this.state.loading}
                                                onClick={() => this.upload(row)}
                                            >
                                                Upload
                                            </Button>
                                    }
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );

    }

    render() {
        return (
            <div>
                <div style={{ display: "flex" }}>
                    <TextField
                        style={{ margin: "20px" }}
                        label="Search"
                        value={this.state.search}
                        onChange={(e) => {
                            this.setState({ search: e.target.value })
                        }}
                    />
                    <FormGroup row>
                        <FormControlLabel
                            control={<Switch checked={this.state.nocache === 1} onChange={() => this.setState({ nocache: this.state.nocache === 1 ? 0 : 1 })} />}
                            label="No Cache"
                        />
                    </FormGroup>
                    <Button
                        disabled={this.state.loading}
                        onClick={() => this.callUnsplash()}
                    >
                        Unsplash
                    </Button>
                    <Button
                        disabled={this.state.loading}
                        onClick={() => this.callPixabay()}
                    >
                        Pixabay
                    </Button>
                </div>
                <div>
                    {this.renderData()}
                </div>
            </div>
        )
    }
}

export default SearchUnsplashImages
