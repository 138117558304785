import * as React from 'react';
import { FC, memo, useMemo, ReactElement, useState } from 'react';
import PropTypes from 'prop-types';
import ImageEye from '@material-ui/icons/Add';
import ImageEye2 from '@material-ui/icons/TimerOutlined';
import { Link } from 'react-router-dom';
import { linkToRecord, Record } from 'ra-core';
import { stringify } from 'query-string'

import { Button } from 'react-admin';

const ShowButton = ({
    resource,
    basePath = '',
    icon = defaultIcon,
    label = 'Create another',
    record,
    scrollToTop = true,
    ...rest
}) => {

    return (
        <Button
            label={label}
            onClick={() => {
                window.location = `/#/${resource}/create`
            }}
            {...(rest)}
        >
            {<ImageEye />}
        </Button>
    )
};

const defaultIcon = <ImageEye />;

// // useful to prevent click bubbling in a datagrid with rowClick
// const stopPropagation = e => e.stopPropagation();

ShowButton.propTypes = {
    basePath: PropTypes.string,
    icon: PropTypes.element,
    label: PropTypes.string,
    record: PropTypes.any,
    scrollToTop: PropTypes.bool,
};

// const PureShowButton = memo(
//     ShowButton,
//     (props, nextProps) =>
//         (props.record && nextProps.record
//             ? props.record.id === nextProps.record.id
//             : props.record == nextProps.record) && // eslint-disable-line eqeqeq
//         props.basePath === nextProps.basePath &&
//         props.to === nextProps.to &&
//         props.disabled === nextProps.disabled
// );

export default ShowButton;