import * as React from 'react';
import { FC, memo, ElementType } from 'react';
import get from 'lodash/get';
import Typography, { TypographyProps } from '@material-ui/core/Typography';
import { sanitizeFieldRestProps } from 'react-admin';
import CodeEditor from '@uiw/react-textarea-code-editor';


const TextField = memo(
    ({ className, source, record = {}, emptyText, ...rest }) => {
        const value = get(record, source);

        return (
            <CodeEditor
                disabled={true}
                value={value}
                language="js"
                onChange={(e) => { }}
                padding={15}
                style={{
                    width: "100%",
                    borderBottom: "solid 1px grey",
                    fontSize: 12,
                    backgroundColor: "#f5f5f5",
                    fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
                }}
                className={className}
            />
        );
    }
);

// what? TypeScript looses the displayName if we don't set it explicitly
TextField.displayName = 'CodeField';

TextField.defaultProps = {
    addLabel: true,
};

export default TextField;