
import React from 'react'
import {
    List,
    Datagrid,
    Edit,
    Create,
    SimpleForm,
    DateField,
    TextField,
    ArrayField,
    SingleFieldList,
    EditButton,
    TextInput,
    DateInput,
    Responsive,
    SimpleList,
    SimpleShowLayout,
    ShowButton,
    Show,
    UrlField,
    SelectInput,
    Filter,
    useListContext,
    TopToolbar,
    CreateButton,
    ExportButton,
    Pagination,
    Button,
    ChipField,
    required,
    sanitizeListRestProps,
    SaveButton,
    DeleteButton,
    Toolbar,
    NumberInput,
    FileInput,
    FileField,
    translate,
    ImageField,
    ReferenceField,
    ReferenceInput,
    RichTextField,
    BooleanField,
    BooleanInput,
    AutocompleteInput,
    FunctionField,
} from 'react-admin'
import { cloneElement, useMemo } from 'react'
import PropTypes from 'prop-types'
import BookIcon from '@material-ui/icons/BookOutlined'
import RichTextInput from 'ra-input-rich-text';
import OptionalImageField from '../Customs/OptionalImageField'
import DuplicateButton from '../Customs/DuplicateButton'
import TinyInput from '../Customs/TinyInput'
import dataProvider from '../dataProvider'

export const LegaleIcon = BookIcon

const Legale_Filter = (props) => (
    <Filter {...props}>
        <TextInput source="title" options={{ maxLength: 1024 }} />
        <ReferenceInput source="website" reference="Website" perPage={10000}>
            <AutocompleteInput optionText="ndd" />
        </ReferenceInput>
        <TextInput source="type" />
    </Filter>
)

const ListActions_Store = {}

const ListActions = (props) => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props
    let {
        currentSort,
        resource,
        displayedFilters,
        filterValues,
        hasCreate,
        basePath,
        selectedIds,
        showFilter,
        total,
    } = useListContext()
    ListActions_Store.filterValues = filterValues
    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
            <CreateButton basePath={basePath} />
        </TopToolbar>
    )
}

export const LegaleList = (props) => (
    <List {...props} filters={<Legale_Filter />} actions={<ListActions />} perPage={10}>
        <Responsive
            small={
                <SimpleList linkType="show"
                    primaryText={record => record ? record.email : ''}
                />
            }
            medium={
                <Datagrid>
                    <ReferenceField source="website" reference="Website" >
                        <TextField source="ndd" />
                    </ReferenceField>
                    <TextField source="type" />
                    <FunctionField label="Words Count" render={(record) => record.content.split('\n').join(' ').split(' ').filter(e => !!e).length} />
                    <DateField source="createdAt" showTime={true} sortable={true} />
                    <DateField source="updatedAt" showTime={true} sortable={true} />
                    <EditButton />
                    <ShowButton />
                    <DuplicateButton />
                </Datagrid>
            }
        />
    </List>
)


export const LegaleShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <ReferenceField source="website" reference="Website" >
                <TextField source="ndd" />
            </ReferenceField>
            <TextField source="type" />
            <FunctionField label="Words Count" render={(record) => record.content.split('\n').join(' ').split(' ').filter(e => !!e).length} />
            <RichTextField source="content" />
            <DateField source="createdAt" showTime={true} />
            <DateField source="updatedAt" showTime={true} />
        </SimpleShowLayout>
    </Show>
)

const CustomToolBar = props => <Toolbar {...props} ><SaveButton /></Toolbar>


export const LegaleEdit = (props) => (
    <Edit {...props} undoable={false} toolbar={<CustomToolBar />} >
        <SimpleForm redirect="show">
            <ReferenceInput source="website" reference="Website" validate={required()} >
                <AutocompleteInput optionText="ndd" />
            </ReferenceInput>
            <TextInput source="type" />
            {/* <TinyInput source="content" validate={required()} /> */}
            <RichTextInput source="content" validate={required()} />
        </SimpleForm>
    </Edit>
)

export const LegaleCreate = (props) => (
    <Create {...props}>
        <SimpleForm redirect="show">
            <ReferenceInput source="website" reference="Website" validate={required()} >
                <AutocompleteInput optionText="ndd" />
            </ReferenceInput>
            <TextInput source="type" />
            <RichTextInput source="content" validate={required()} />
        </SimpleForm>
    </Create>
)

