
import React from 'react'
import {
    List,
    Datagrid,
    Edit,
    Create,
    SimpleForm,
    DateField,
    TextField,
    ArrayField,
    SingleFieldList,
    EditButton,
    TextInput,
    DateInput,
    Responsive,
    SimpleList,
    SimpleShowLayout,
    ShowButton,
    Show,
    UrlField,
    SelectInput,
    Filter,
    useListContext,
    TopToolbar,
    CreateButton,
    ExportButton,
    Pagination,
    Button,
    ChipField,
    required,
    sanitizeListRestProps,
    useRecordContext,
    SaveButton,
    DeleteButton,
    Toolbar,
    ArrayInput,
    SimpleFormIterator,
    NumberInput,
    FileInput,
    FileField,
    SearchInput,
    ImageField,
    FunctionField,
    ReferenceArrayField,
    ReferenceInput,
    ReferenceArrayInput,
    SelectArrayInput,
    ReferenceField,
    AutocompleteInput,
} from 'react-admin'
import { cloneElement, useMemo } from 'react'
import PropTypes from 'prop-types'
import BookIcon from '@material-ui/icons/PermMedia'
import ShortUrlField from '../Customs/ShortUrlField'
import MultipleField from '../Customs/MultipleField'
import MultipleInput from '../Customs/MultipleInput'
import DeleteAllButton from '../Customs/DeleteAllButton'
import ImportButton from '../Customs/ImportButton'
import CreateOtherButton from '../Customs/CreateOtherButton'
import ApiSelectInput from '../Customs/ApiSelectInput'
import OptionalImageField from '../Customs/OptionalImageField'
import dataProvider from '../dataProvider'

const API_URL = process.env.REACT_APP_API_HOSTNAME

export const MediaIcon = BookIcon

const Media_Filter = (props) => (
    <Filter {...props}>
        <SearchInput source="search" alwaysOn />
        <TextInput source="s3_key" options={{ maxLength: 1024 }} />
        <ReferenceInput source="website" reference="Website" perPage={10000} >
            <AutocompleteInput optionText="ndd" />
        </ReferenceInput>
        {/* <TextInput source="search" options={{ maxLength: 1024 }} /> */}
    </Filter>
)

const ListActions_Store = {}

const ListActions = (props) => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props
    let {
        currentSort,
        resource,
        displayedFilters,
        filterValues,
        hasCreate,
        basePath,
        selectedIds,
        showFilter,
        total,
    } = useListContext()
    ListActions_Store.filterValues = filterValues
    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
            <CreateButton basePath={basePath} />
        </TopToolbar>
    )
}

const ShowActions = (props) => {
    const record = useRecordContext(props);
    // const { hasEdit } = useResourceDefinition();
    // if (!hasEdit) {
    //     return null;
    // }
    return (
        <TopToolbar className={props.className}>
            <EditButton record={record} />
            <CreateOtherButton resource={props.resource} record={record} />
        </TopToolbar>
    );
};

export const MediaList = (props) => (
    <List {...props} filters={<Media_Filter />} actions={<ListActions />} perPage={50} bulkActionButtons={false} sort={{ field: 'id', order: 'DESC' }} >
        <Responsive
            small={
                <SimpleList linkType="show"
                    primaryText={record => record ? record.s3_key : ''}
                />
            }
            medium={
                <Datagrid>
                    <TextField source="id" sortable={true} />
                    <TextField source="nameWithSize" sortable={false} />
                    <OptionalImageField source='url' title='url' sortable={false} />
                    <ReferenceArrayField label="Websites" reference="Website" source="websites">
                        <SingleFieldList>
                            <ChipField source="ndd" />
                        </SingleFieldList>
                    </ReferenceArrayField>
                    <DateField source="createdAt" showTime={true} sortable={true} />
                    <EditButton />
                    <ShowButton />
                </Datagrid>
            }
        />
    </List>
)

export const MediaShow = (props) => (
    <Show {...props} actions={<ShowActions />}>
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="s3_key" />
            <FileField source='url' title='url' />
            <OptionalImageField source='url' />
            <TextField source='origin_url' />
            <TextField source='metadata' />
            <ReferenceArrayField label="Websites" reference="Website" source="websites">
                <SingleFieldList>
                    <ChipField source="ndd" />
                </SingleFieldList>
            </ReferenceArrayField>
            <DateField source="createdAt" showTime={true} />
            <DateField source="updatedAt" showTime={true} />
        </SimpleShowLayout>
    </Show>
)

const CustomToolBar = props => <Toolbar {...props} ><SaveButton /></Toolbar>

export const MediaEdit = (props) => (
    <Edit {...props} undoable={false} toolbar={<CustomToolBar />} >
        <SimpleForm redirect="show">
            {/* <TextInput source="name" validate={required()} />
            <FileInput label={"File 50Mo Max"} source="filedata" validate={required()} maxSize={50 * 1024 * 1024}>
                <FileField source="src" title="title" target="_blank" />
            </FileInput> */}
            <ArrayInput label="Webites" source="website_values">
                <SimpleFormIterator>
                    <ReferenceInput source="value" reference="Website" perPage={10000} >
                        <AutocompleteInput optionText="ndd" />
                    </ReferenceInput>
                </SimpleFormIterator>
            </ArrayInput>

        </SimpleForm>
    </Edit>
)

export const MediaCreate = (props) => (
    <Create {...props}>
        <SimpleForm redirect="show">
            <TextInput source="name" validate={required()} />
            <FileInput label={"File 50Mo Max"} source="filedatas" validate={required()} maxSize={50 * 1024 * 1024} multiple >
                <FileField source="src" title="title" target="_blank" />
            </FileInput>
            {/* <ReferenceArrayInput source="website" reference="Website" perPage={10000} >
                <SelectArrayInput optionText="ndd" />
            </ReferenceArrayInput> */}
            <ArrayInput source="websites">
                <SimpleFormIterator>
                    <ReferenceInput source="value" reference="Website" perPage={10000} >
                        <AutocompleteInput optionText="ndd" />
                    </ReferenceInput>
                </SimpleFormIterator>
            </ArrayInput>
        </SimpleForm>
    </Create>
)
