import React, { useEffect, useState } from 'react';
import { useInput, FieldTitle, InputProps } from 'react-admin';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Tooltip from '@material-ui/core/Tooltip';

import dataProvider from '../dataProvider'
import WebsitesSelector from '../components/WebsitesSelector';

const GalleryInput = (props) => {
  const {
    input: { name, onChange, value, ...rest },
    meta: { touched, error },
  } = useInput(props);

  const optionText = props.optionText || 's3_key'

  const [internalValue, setInternalValue] = useState(value);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [medias, setMedias] = useState([]);
  const [website, setWebsite] = useState(props.record.website || null);
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(20);

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    if (internalValue !== value) {
      dataProvider.getList('Media', {
        pagination: { page: 1, perPage: 10000 },
        sort: { field: "createdAt", sort: "desc" },
        filter: { id: value }
      }).then(response => {
        setMedias(response.data)
      })
    }
  };

  const handleImageSelect = (imageUrl) => {
    setInternalValue(imageUrl.id);
    onChange(imageUrl.id);
    handleDialogClose();
  };

  const handleWebsiteSelect = (website) => {
    setWebsite(website);
    setLimit(20)
    dataProvider.getList('Media', {
      pagination: { page: 1, perPage: 10000 },
      sort: { field: "createdAt", sort: "desc" },
      filter: { website }
    }).then(response => {
      setMedias(response.data)
    })

  };

  useEffect(() => {
    dataProvider.getList('Media', {
      pagination: { page: 1, perPage: 10000 },
      sort: { field: "createdAt", sort: "desc" },
      filter: { id: value }
    }).then(response => {
      setMedias(response.data)
    })
    if (website) {
      handleWebsiteSelect(website)
    }
  }, [])

  return (
    <div>
      <TextField
        {...rest}
        name={name}
        label={<FieldTitle label={props.label} source={name} resource={props.resource} style={{ marginBottom: "5px" }} />}
        type="text"
        value={internalValue}
        error={!!(touched && error)}
        helperText={touched && error}
        onClick={handleDialogOpen}
      // InputProps={{
      //   endAdornment: (
      //     <Button variant="contained" color="primary" onClick={handleDialogOpen}>
      //       Choose a media
      //     </Button>
      //   ),
      // }}
      />
      {
        medias && <div style={{ marginTop: "10px", marginBottom: "10px", fontWeight: "bold" }}>{(medias.filter(e => internalValue === e.id)[0] || {})[optionText]}</div>
      }
      {
        medias && !props.noImage && <div style={{ marginTop: "10px", marginBottom: "20px", fontWeight: "bold" }} onClick={handleDialogOpen} ><img src={medias.filter(e => internalValue === e.id)[0]?.url} style={{ minWidth: "75px", height: "150px", border: '2px solid grey' }} /></div>
      }



      <Dialog open={dialogOpen} onClose={handleDialogClose} maxWidth="xl" fullWidth>
        <DialogTitle>Choose a media</DialogTitle>
        <DialogContent>
          <WebsitesSelector
            handleWebsiteSelect={(websites, selectedWebsite) => {
              if (selectedWebsite) {
                handleWebsiteSelect(selectedWebsite)
              }
            }}
          />
          <TextField
            {...rest}
            name={"search"}
            label={<FieldTitle label={"Search"} style={{ marginBottom: "5px" }} />}
            type="text"
            value={search}
            onChange={(e) => { setSearch(e.target.value); setLimit(20) }}
          />
          {
            website && (
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {(medias || []).filter(e => e.s3_key.includes(search)).slice(0, limit).map((e, index) => (
                  <div key={index}
                    style={{ flex: '0 0 auto', margin: '10px' }}
                    onClick={() => handleImageSelect(e)}
                  >
                    {
                      props.noImage ? (
                        <div style={{ minWidth: "75px", border: '2px solid grey', cursor: "pointer" }}>{e.s3_key}</div>
                      ) : (
                        <Tooltip title={e.s3_key} arrow>
                          <img src={e.url} style={{ minWidth: "75px", height: "150px", border: '2px solid grey', cursor: "pointer" }} />
                        </Tooltip>
                      )
                    }
                  </div>
                ))}
              </div>
            )
          }
          <div></div>
          {
            medias.filter(e => e.s3_key.includes(search)).length > limit && (
              <Button onClick={() => setLimit(limit + 20)} color="primary">
                More
              </Button>
            )
          }

        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div >
  );
};
export default GalleryInput;
